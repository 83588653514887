const redirectToLogin = () => {
  const url = window.location.origin + "/signout";
  localStorage.setItem("logout_status", "show_login_button");
  console.log("Logout url => ", url);
  if (
    window.config.environment === "local" ||
    process.env.REACT_APP_ENVIRONMENT === "dev" ||
    process.env.REACT_APP_ENVIRONMENT === "qa" ||
    process.env.REACT_APP_ENVIRONMENT === "demo"
  ) {
    window.location.href = window.location.origin + "/";
    return;
  } else {
    window.location.href = url;
  }
};

export default redirectToLogin;
