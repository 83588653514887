import { SvgIcon} from "@mui/material";


export const ThumbsUpIcon = () => {
    return (
      <SvgIcon>
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5016 8.11484V5.02913C12.5016 3.75098 11.4654 2.71484 10.1873 2.71484L7.10156 9.6577V18.1434H16.0656C16.8349 18.1521 17.493 17.5927 17.6084 16.832L18.673 9.88913C18.7409 9.44157 18.6089 8.98681 18.3118 8.6452C18.0148 8.30363 17.5827 8.1097 17.1301 8.11484H12.5016Z"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M7.10177 9.57056H5.04206C4.13834 9.55458 3.36579 10.3032 3.24463 11.1989V16.5989C3.36579 17.4946 4.13834 18.1577 5.04206 18.1417H7.10177V9.57056Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  };
  
  export const ThumbsDownIcon = () => {
    return (
      <SvgIcon>
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.90717 12.7435V15.8292C8.90717 17.1074 9.94332 18.1435 11.2215 18.1435L14.3072 11.2007V2.71494H5.34317C4.57384 2.70625 3.91567 3.26569 3.80031 4.02637L2.73574 10.9692C2.66781 11.4168 2.79985 11.8715 3.09688 12.2132C3.39391 12.5547 3.82596 12.7487 4.2786 12.7435H8.90717Z"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M14.3071 2.71317H16.3668C17.2706 2.69719 18.0431 3.36033 18.1643 4.25603V9.65603C18.0431 10.5517 17.2706 11.3006 16.3668 11.2846H14.3071V2.71317Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    );
  };
  