import React, { useState, useContext, useEffect, useRef } from "react";
import { PyramidContext } from "../../Contexts/uiContext";
import services from "../../Services/patientServices";
import FilterTable from "./DataTable";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import { Avatar, Box, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toPluralize } from "../../Utils/stringOprations";
import useDidMountEffect from "../../Utils/customHooks/useDidMountEffect";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AdvancedFiltersList from "./AdvancedFiltersList";
import AppliedFilterList from "./AppliedFilterList";
import "./_PatientList.scss";
import ColumnFilter from "./ColumnFilter";
// import filterIcon from "../../Assets/Images/filter_icon.png";
import saveIcon from "../../Assets/Images/save_icon.png";
import SaveFilter from "../../Components/Modal/SaveFilter";
import { v4 as uuidv4 } from 'uuid';
import { addJoinTypeKeyInFilters , deepClone} from "../../Utils/filterPatientList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import HeatMap from "../Heatmap/Heatmap";
import algoliasearch from "algoliasearch";
import staticFilters from "./staticFilters";
import { AnalyticsContext } from "../../Contexts/analyticsContext";
import axios from 'axios';
import withRouter from "../../Utils/withRouterHOC";

const appId = "LK0QLFUCKW";
const apiKey = "016f51b4cf791ea2696890563334122f";
const searchClient = algoliasearch(appId, apiKey);

const PatientsList = () => {
  let location = useLocation();
  let history = useNavigate()
  const { setPageTitle } = useContext(MetaDetailsContext);
  const { info, setInfo, setPatientData, setFilterData } =
    useContext(PyramidContext);
  const { state, dispatch } = useContext(DropdownFilterContext);
  const { patientSetInfo } = useContext(PatientSetContext);
  const { event } = useContext(AnalyticsContext);
  const [custAttrVal, setCustAttrVal] = useState();
  const [filterKey, setFilterKey] = useState(null);
  const [options, setOptions] = useState([]);
  const [openSaveFilter, setOpenSaveFilter] = useState(false);
  const [currentTab, setCurrentTab] = React.useState("list_view");
  const [pageSize, setPageSize] = useState(1);
  const [fetchingTotalCount, setFetchingTotalCount] = useState(true);
  const cancelTokenSourceRef = useRef([]);

  // Define a function to perform the search recursively
  async function getAllHits(index, org_id, page = 0, allHits = []) {
    const hitsPerPage = 1000;
    
    // Perform the search
    const result = await index.search('', {
      facetFilters: [`org_id:${org_id}`],
      hitsPerPage: hitsPerPage,
      page: page
    });
    console.log('Result: ', result);

    const hits = result.hits;
    allHits = allHits.concat(hits);

    // If there are more hits to retrieve, recursively fetch them
    console.log('Next hit: ', hits.length, hitsPerPage);

    if (hits.length === hitsPerPage) {
      console.log('Its a hit');
      return getAllHits(index, org_id, page + 1, allHits);
    } else {
      return allHits;
    }
  }

  useEffect(() => {
    setPageTitle(`FHS: ${toPluralize(patientSetInfo.patient_name)}`);
    //set Cohort Name as navigated from 'Intervention Analysis'

    if (patientSetInfo) {
      dispatch({ type: "set-is-fetching", payload: true });

      var environment = window.config.environment === 'local' ? 'dev' : window.config.environment;
      const index = searchClient.initIndex(`${environment}_PATIENT_LIST_FILTERS`);
      const { org_id } = JSON.parse(localStorage.getItem("user")).user;

      getAllHits(index, org_id).then((hits) => {
          const staticFilterOptions = staticFilters();
          hits.push( ...staticFilterOptions );

          console.log(`Retrieved a total of`, hits.length, `filters: `, hits);

          if(hits.length > 0) {
          // Extract titles and orders
          const titlesWithOrder = hits.map(datum => ({ title: datum.title, order: datum.order }));
          
          // Sort titles based on order
          const sortedTitles = [...new Set(titlesWithOrder.map(datum => datum.title))]
            .sort((a, b) => {
              const orderA = titlesWithOrder.find(datum => datum.title === a).order;
              const orderB = titlesWithOrder.find(datum => datum.title === b).order;
              return orderA - orderB;
            });

            console.log('Titles: ', sortedTitles);

            var modified_filter = sortedTitles.map(title => {
              const options = hits.filter(hit => hit.title === title);
              const key = options[0].mnemo;
              return {
                title: title,
                key: key,
                type: options[0].type, 
                options: options.map((ele) => {
                  if(key === "outlier"){
                    return {
                      name: `${key}_${ele.abbr}`,
                      key: ele.key,
                      label: ele.label,
                      selected: 0,
                      mnemo: key,
                      value: ele.value,
                      abbr: ele.abbr,
                      options: ele.options,
                      filterType : ele.filterType
                    };
                  }
                  else{
                    return {
                      name: `${key}_${ele.abbr}`,
                      key: ele.key,
                      label: ele.label,
                      selected: 0,
                      mnemo: key,
                      value: ele.value,
                      abbr: ele.abbr
                    };
                  }
                })
            };
          });

          console.log('Modified filters: ', modified_filter)


          //Set saved Common FHS filters to dropdown context
          let selectedFiltersCheckbox = [];
          if (
            state.isSavedFilterApplied &&
            state.selectedFiltersCheckbox.length
          ) {
            state.selectedFiltersCheckbox.forEach((ele) => {
              modified_filter = modified_filter.map((filter) => {
                let { title, key, options } = filter;
                options = options.map((option) => {
                  if (
                    option.key === ele.key &&
                    String(option.value) === ele.individual
                  ) {
                    option.selected = ele.selected;
                    selectedFiltersCheckbox.push(option.name);
                  }
                  return option;
                });
                return { title, key, options };
              });
            });
          }
          dispatch({
            type: "set-checkboxes-options",
            payload: {
              options: modified_filter,
              checkBoxes: selectedFiltersCheckbox,
            },
          });
          setOptions(modified_filter);
          // setOptionsCopy(modified_filter);
          setFilterData(modified_filter[1].options);
        }

        // Merge the Risk-Stratus filter object from 'Overview' page
        var risk_data = null;
        var selectedFiltersCheckbox = [];
        if (info.prevui === "stats" && info.pyramidRiskStatus !== "none") {
          risk_data = {
            key: info.key,
            title: "Risk Stratus",
            stratus: info.pyramidRiskStatus,
            apply: true,
            dropdownSelected: true,
            checkboxSelected: false,
            from: "",
            to: "",
            individual: "",
            fromToMode: true,
            qf: false,
          };
          dispatch({ type: "set-filters-other", payload: risk_data });
        } else if (
          info.prevui === "interventions" ||
          info.prevui === "contributors"
        ) {
          if (info.abbr) {
            const { filterCheckboxes, filterOptions } = handlePreAppliedFilters(
              [{ name: info.abbr, selected: 1, mnemo: info.mnemo }],
              modified_filter
            );
            selectedFiltersCheckbox = filterCheckboxes;
            openMenu(info.abbr, filterOptions);
          }
        } else if(info.prevui === "summary"){
          if(info.riskFilter.length){
            let _modified_filter = modified_filter;
            const {filterCheckboxes, filterOptions} = handlePreAppliedFilters(info.riskFilter, _modified_filter);
            info.riskFilter.forEach((ele)=>{
              _modified_filter = filterOptions
              selectedFiltersCheckbox.push(filterCheckboxes[0]);
              openMenu(ele.name, filterOptions);
            })
          }
        }

        //Get patient list data
        const data = {
          appliedFilterCheckboxes: state?.appliedFilterCheckboxes && state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList?.length
            ? state?.appliedFilterCheckboxes
            : {
              isMergingFilters: false,
              appliedFilterCheckboxesList: []
            },
          selectedFilters: state?.selectedFilters.length
            ? state.selectedFilters
            : risk_data
            ? [risk_data]
            : [],
          selectedFiltersCheckbox: state?.selectedFiltersCheckbox.length
            ? selectedFiltersCheckbox.concat(state.selectedFiltersCheckbox)
            : selectedFiltersCheckbox,
          page: state.page,
          inactivePatients: state.inactivePatients,
          nlp_filter: state.nlp_filter,
        };

        !((info.prevui === "summary" && info.riskFilter.length) 
        || ((info.prevui === "interventions" || info.prevui === "contributors") && info.abbr))
         && fetchData("initial page mount", data);
      });

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterName]);

  useEffect(() => {
    return () => {
      //run cleanup only when saved filter is not applied
      if (!state.isSavedFilterApplied) {
        dispatch({ type: "reset-other-filters", payload: [] });
        setInfo({
          abbr: "",
          currentui: info.prevui || "",
          prevui: info.currentui || "",
          key: "",
          pyramidRiskStatus: "none",
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidMountEffect(() => {
    fetchData("patient view toggled", state);

    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.forEach(source => {
          source.cancel('Request canceled due to tab switch or component unmount.');
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  //Detect changes in sorting
  useDidMountEffect(() => {
    fetchData("sorting", state);
  }, [JSON.stringify(filterKey)]);

  //Detect changes in dropdown filters
  useDidMountEffect(() => {
    dispatch({ type: "set-page", payload: 1 });
    fetchData("dropdown filter", { ...state, page: 1 });
  }, [JSON.stringify(state.selectedFilters)]);

    //Detect changes in dropdown filters
  useDidMountEffect(() => {
    if(state.appliedFilterCheckboxes.isMergingFilters){
      dispatch({ type: "set-page", payload: 1 });
      fetchData("dropdown filter", { ...state, page: 1 });
    }
  }, [JSON.stringify(state.appliedFilterCheckboxes)]);

  //Detect changes in the pagination
  useDidMountEffect(() => {
    fetchData("pagination", state);
  }, [JSON.stringify(state.page)]);

  //Detect changes in the inactive patients checkbox
  useDidMountEffect(() => {
    fetchData("inactive-patients", state);
  }, [JSON.stringify(state.inactivePatients)]);

  // Detect changes in the nlp filter checkbox button
  useDidMountEffect(() => {
    fetchData("nlp-filter", state);
  }, [JSON.stringify(state.nlp_filter)]);

  useDidMountEffect(() => {
    if(currentTab === "map_view"){
      fetchData("geospatial", state);
    }
  }, [JSON.stringify(pageSize)]);

  // Detect changes in the info object to remove pyramid filters
  useEffect(() => {
    if (location?.state?.uititle && info.prevui === "stats" && info.pyramidRiskStatus === "none") {
      updateLocationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  const openMenu = (name, filterOptions) => {
    var findItem = filterOptions.filter((item) => {
      const index = item.options.find((ele) => ele.name === name);
      if (index) return true;
      else return false;
    });
    if (findItem.length) {
      // const index = filterOptions.findIndex(
      //   (item) => item?.key === findItem[0].key
      // );
      // handleExpandChange(`panel${index}`)(null, true);
    }
  };

  const fetchData = async (type, state) => {
    try {
      const {
        selectedFilters,
        selectedFiltersCheckbox,
        page,
        columns,
        selectedColumns,
        inactivePatients,
        nlp_filter,
      } = state;
  
      if (currentTab === "list_view" || (currentTab === "map_view" && pageSize === 1)) {
        dispatch({ type: "set-is-fetching", payload: true });
        setFetchingTotalCount(true);
      }
  
      const filters = selectedFilters.concat(selectedFiltersCheckbox);
      let filters2 = addJoinTypeKeyInFilters(
        deepClone(state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList),
        deepClone(filters)
      );

      if(info?.pyramidRiskStatus !== "none"){
        let index = filters2?.findIndex((filter) => filter?.title === "Risk Stratus")
        if(index === -1){
          filters2 = [...filters2,...selectedFilters];
        }
      }

      const { patient_set_id: pid, selected_date } = patientSetInfo;
      const body = {
        filters: filters2,
        sortBy: filterKey,
        page,
        selected_date,
        columns: selectedColumns,
        inactivePatients,
        limit: 10,
        nlp_filter: Object.assign(
          ...Object.keys(nlp_filter).map((k) => ({ [k]: nlp_filter[k].value }))
        ),
      };
  
      if (currentTab === "map_view") {
        await fetchGeospatialData(body, pid);
      } else {
        if (cancelTokenSourceRef?.current) {
          cancelTokenSourceRef?.current?.forEach((source) => {
            source?.cancel("Previous request canceled.");
          });
          cancelTokenSourceRef.current = [];
        }
  
        const filteredPatientsPromise = services.patientsList(body, pid);
        const patientTotalcountPromise = services.patientsListTotalCount(body, pid);
  
        let filteredPatientsResponseReceived = false;
        let patientTotalcountResponseReceived = false;
  
        let patientTotalcountData = null;
  
        const handleFilteredPatientsResponse = (response) => {
          filteredPatientsResponseReceived = true;
  
          // Process filteredPatients immediately
          setCustAttrVal(response.data.patientset.custAttrValue);
          setPatientData(response);
  
          if (!columns?.length) {
            dispatch({
              type: "set-patient-columns",
              payload: response.data.columns,
            });
          }
          dispatch({
            type: "set-patients",
            payload: response.data.patientset.patients,
          });
          dispatch({ type: "set-is-fetching", payload: false });
  
          // If count data is already received, process it now
          if (patientTotalcountResponseReceived) {
            processPatientTotalCount();
          }
        };
  
        const handlePatientTotalcountResponse = (response) => {
          patientTotalcountData = response;
          patientTotalcountResponseReceived = true;
  
          // If list data is already received, process count data now
          if (filteredPatientsResponseReceived) {
            processPatientTotalCount();
          }
        };
  
        const processPatientTotalCount = () => {
          setFetchingTotalCount(true);
          dispatch({
            type: "set-total-count",
            payload: patientTotalcountData.data.count,
          });
          setFetchingTotalCount(false);
        };
  
        // Start loading filteredPatients and count at the same time without blocking each other
        filteredPatientsPromise.then(handleFilteredPatientsResponse);
        patientTotalcountPromise.then(handlePatientTotalcountResponse);
  
        // Ensure both promises are awaited to handle any errors
        await Promise.all([filteredPatientsPromise, patientTotalcountPromise]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchGeospatialData = async (body, pid) => {
    const BATCH_SIZE = 5000;
    const MAX_ITEMS = 250000;
    const MAX_CONCURRENT_REQUESTS = 5;
    let batchPromises = [];
    let offset = 0;
    let totalCoordinates = 0;
    let isExhausted = false;
  
    const fetchBatch = async (currentOffset) => {
      try {
        body.offset = currentOffset;
        body.limit = BATCH_SIZE;

        // Create new cancel token source for the new request
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSourceRef.current.push(cancelTokenSource);
        const response = await services.listCoordinates(body, pid, cancelTokenSource?.token);
        const coordinates = response.data.coordinates || [];
        const markers = response.data.markerList || [];
        return { coordinates, markers };
      } catch (error) {
        console.error('Error fetching data:', error);        
        return {coordinates: [], markers: []}
      }
    };
  
    while (totalCoordinates < MAX_ITEMS && !isExhausted) {
      while (batchPromises.length < MAX_CONCURRENT_REQUESTS && totalCoordinates < MAX_ITEMS) {
        batchPromises.push(fetchBatch(offset));
        offset += BATCH_SIZE;
      }
  
      const results = await Promise.all(batchPromises);
      batchPromises = []; // Reset batchPromises for the next set of requests
  
      // eslint-disable-next-line no-loop-func
      results.forEach((item) => {
        const { coordinates } = item;
        if (coordinates.length === 0) {
          isExhausted = true; // No more records available
        } else {
          totalCoordinates += coordinates.length;
          dispatch({
            type: "set-geo-spatial",
            payload: {
              coordinates
            },
          });      
        }
      });
  
      if (totalCoordinates >= MAX_ITEMS || isExhausted) {
        dispatch({ type: "set-is-fetching", payload: false });
        console.log('Request complete.');
      }
    }
  };


  // Set the name of the last field that sorts the data along with sort type
  const setKey = (filterkey, key, direction) => {
    setFilterKey({
      name: filterkey,
      key: key,
      direction: direction,
    });
    // GA - Track Sorting applied
    event("patient_list_sorting", { column: filterKey });
  };

  const [sstate, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...sstate, [anchor]: open });
  };

  // If filters are pre applied from summary or any other pages
  const handlePreAppliedFilters = (
    items,
    checkboxes = state.identifiers_checkbox.options_checkbox
  ) => {
  
    let selected_checkboxes = [...state.identifiers_checkbox.selected_checkboxes];
    let toggled_checkboxes = {
      selected_checkboxes,
      options_checkbox: [...checkboxes],
    };
  
    items.forEach((item) => {
      const { name, selected } = item;
  
      if (selected !== 2) {
        const selectedFilters = selected_checkboxes.find((obj) => obj === name);
  
        selected_checkboxes = selectedFilters
          ? selected_checkboxes.filter((obj) => obj !== name)
          : [...selected_checkboxes, name];
      }
  
      toggled_checkboxes = {
        selected_checkboxes,
        options_checkbox: toggled_checkboxes.options_checkbox.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return name === option.name
                ? {
                    ...option,
                    selected,
                  }
                : option;
            }),
          };
        }),
      };
  
      dispatch({
        type: "update-checkboxes",
        payload: {
          checkboxSelected: true,
          selected_checkboxes: toggled_checkboxes.selected_checkboxes,
          options_checkbox: toggled_checkboxes.options_checkbox,
        },
      });
  
      if (state.identifiers_dropdown.qf) {
        dispatch({
          type: "reset-state",
          payload: { id: state.identifiers_dropdown.key },
        });
      }
  
      setOptions(toggled_checkboxes.options_checkbox);
    });
  
    const selectedFiltersCheckbox = toggled_checkboxes.options_checkbox
      .flatMap((m) => m.options)
      .filter((n) => n.selected === 1 || n.selected === 2)
      .map((o) => {
        if (o.key !== "outlier") {
          return {
            key: o.key,
            dropdownSelected: false,
            checkboxSelected: o.selected === 1 || o.selected === 2 ? true : false,
            queried: o.selected === 2 ? false : o.selected === 1 ? true : null,
            apply: false,
            qf: false,
            individual: o.value?.toString(),
            selected: o.selected,
            to: "",
            from: "",
            fromToMode: false,
          };
        }
        return null;
      })
      .filter((item) => item !== null); // Filter out any null values
  
    const _selectedFiltersCheckbox = [...state.selectedFiltersCheckbox];
    let ___selectedFiltersCheckbox = _selectedFiltersCheckbox.filter(
      (ele) => ele.key === "outlier"
    );
  
    items.forEach((item) => {
      const { mnemo, selected } = item;
  
      if (mnemo && mnemo === "outlier" && selected === 1) {
        const { data } = item;
        const index = ___selectedFiltersCheckbox.findIndex(
          (doc) => doc.individual === data.mnemo
        );
        const outlierFilterData = {
          key: mnemo,
          dropdownSelected: false,
          checkboxSelected: true,
          queried: null,
          apply: false,
          qf: false,
          individual: data.mnemo,
          selected: 1,
          to: "",
          from: "",
          fromToMode: false,
          ...data,
        };
  
        if (index !== -1) {
          ___selectedFiltersCheckbox[index] = outlierFilterData;
        } else {
          ___selectedFiltersCheckbox.push(outlierFilterData);
        }
      }
      if (item && item._mnemo && item._mnemo === "outlier" && selected === 0) {
        const __mnemo = item.name.replace("outlier_", "");
        const index = ___selectedFiltersCheckbox.findIndex(
          (doc) => doc.individual === __mnemo
        );
        ___selectedFiltersCheckbox.splice(index, 1);
      }
    });
  
    if (___selectedFiltersCheckbox.length) {
      ___selectedFiltersCheckbox.forEach((item) => {
        selectedFiltersCheckbox.push(item);
      });
    }
    
    // change 'checkboxTriggered' to false once the checkbox has been selected
    dispatch({ type: "reverse-checkbox", payload: false });
    window.scrollTo(0, 0);
    handlePreAppliedCheckboxFilters(items, checkboxes);

    dispatch({
      type: "set-filters-checkbox",
      payload: selectedFiltersCheckbox,
    });
  
    return {
      filterCheckboxes: selectedFiltersCheckbox,
      filterOptions: toggled_checkboxes.options_checkbox,
    };
  };

  // handle checkbox and options If filters are pre applied from summary or any other pages
  const handlePreAppliedCheckboxFilters = (selectedFilters, checkBoxes) => {
    selectedFilters.forEach((selectedFilter) => {
      const { name, selected, mnemo } = selectedFilter;
      const { appliedFilterCheckboxesList } = state.appliedFilterCheckboxes;
  
      if (selected === 1) {
        let currentSelectedFilters = checkBoxes.filter(({ key }) => key === mnemo)[0];
        let currentSelectedFiltersOptions = currentSelectedFilters?.options?.filter((filter) => filter.name === name)[0];
        let id = uuidv4();
        let newFilter = {
          id,
          title: currentSelectedFilters['title'],
          options: [{ ...currentSelectedFiltersOptions }]
        };
  
        // For Outlier filter
        if (mnemo === "outlier") {
          const isOutlierExist = appliedFilterCheckboxesList.map(item => {
            return item.options.some(option => (option.name === name));
          }).some(option => (option === true));
  
          if (isOutlierExist) {
            let _updatedData = appliedFilterCheckboxesList.map(item => {
              const updatedOptions = item.options.map(option => {
                if (option.name === name) {
                  return { ...option, ...{ selected } };
                }
                return option;
              });
              return { ...item, options: updatedOptions };
            });
  
            dispatch({
              type: "applied-checkbox-filter-update",
              payload: {
                data: _updatedData,
                isMergingFilters: false
              }
            });
  
          } else {
            dispatch({
              type: "applied-checkbox-filter",
              payload: newFilter
            });
          }
  
        } else {
          dispatch({
            type: "applied-checkbox-filter",
            payload: newFilter
          });
        }
      }
  
      if (selected === 2) {
        let updatedData = appliedFilterCheckboxesList.map(item => {
          const updatedOptions = item.options.map(option => {
            if (option.name === name) {
              return { ...option, ...{ selected } };
            }
            return option;
          });
          return { ...item, options: updatedOptions };
        });
        dispatch({
          type: "applied-checkbox-filter-update",
          payload: {
            data: updatedData,
            isMergingFilters: false
          }
        });
      }
  
      if (selected === 0) {
        // when we remove filter which is applied when visit this page from interventions and contributors 
        if (location?.state?.uititle && (info?.abbr === name)) {
          updateLocationData();
        }
        const updatedData2 = appliedFilterCheckboxesList.map(item => {
          const updatedItem = { ...item, options: item.options.filter(option => option.name !== name) };
          return updatedItem.options.length > 0 ? updatedItem : null;
        }).filter(item => item !== null);
        dispatch({
          type: "applied-checkbox-filter-update",
          payload: {
            data: updatedData2,
            isMergingFilters: false
          }
        });
      }
    });
  };
  
  
  // Function to toggle & update filter options as selected from checkboxes
  const handleChange = (
    item,
    checkboxes = state.identifiers_checkbox.options_checkbox
  ) => {
    const { name, selected, mnemo } = item;

    if (selected === 1 || selected === 2) {
      // GA - Track advanced filters
      event("patient_list_common_filters", {
        filter_name: name,
        selected: selected === 2 ? "Inderminate selection" : "Selected",
        mnemo: mnemo,
      });
    }
    if(currentTab === "map_view"){
      resetGeospatialData();
      setPageSize(1)
    }

    let selected_checkboxes;

    // If 'Indeterminate' state i.e 2, then skip adding data to the existing selected checkboxes array;
    if (selected === 2) {
      selected_checkboxes = [...state.identifiers_checkbox.selected_checkboxes];
    } else {
      const selectedFilters =
        state.identifiers_checkbox.selected_checkboxes.find(
          (obj) => obj === name
        );

      selected_checkboxes = selectedFilters
        ? state.identifiers_checkbox.selected_checkboxes.filter(
            (obj) => obj !== name
          )
        : [...state.identifiers_checkbox.selected_checkboxes, name];
    }

    const toggled_checkboxes = {
      selected_checkboxes,
      options_checkbox: [
        ...checkboxes.map((filter) => {
          return {
            ...filter,
            options: filter.options.map((option) => {
              return name === option.name
                ? {
                    ...option,
                    selected,
                  }
                : option;
            }),
          };
        }),
      ],
    };

    dispatch({
      type: "update-checkboxes",
      payload: {
        checkboxSelected: true,
        selected_checkboxes: toggled_checkboxes.selected_checkboxes,
        options_checkbox: toggled_checkboxes.options_checkbox,
      },
    });

    if (state.identifiers_dropdown.qf) {
      dispatch({
        type: "reset-state",
        payload: { id: state.identifiers_dropdown.key },
      });
    }

    setOptions(toggled_checkboxes.options_checkbox);
    // setOptionsCopy(toggled_checkboxes.options_checkbox);

    const selectedFiltersCheckbox = toggled_checkboxes.options_checkbox
      .flatMap((m) => m.options)
      .filter((n) => n.selected === 1 || n.selected === 2)
      .map((o) => {
        if(o.key !== "outlier"){
          return {
            key: o.key,
            dropdownSelected: false,
            checkboxSelected: o.selected === 1 || o.selected === 2 ? true : false,
            queried: o.selected === 2 ? false : o.selected === 1 ? true : null,
            apply: false,
            qf: false,
            individual: o.value?.toString(),
            selected: o.selected,
            to: "",
            from: "",
            fromToMode: false,
          };
        } return null;
      }).filter((item) => item !== null); // Filter out any null values;

    // Applied the outlier filter
    const _selectedFiltersCheckbox = [...state.selectedFiltersCheckbox];
    let ___selectedFiltersCheckbox = _selectedFiltersCheckbox.filter((ele)=>ele.key === "outlier");
    if(mnemo && mnemo === "outlier" && selected === 1){

      // if Applied filter already exit then update only
      const { data } = item;
      const index = ___selectedFiltersCheckbox.findIndex((doc)=>(doc.individual === data.mnemo));
      const outlierFilterData = {
        key: mnemo,
        dropdownSelected: false,
        checkboxSelected:  true,
        queried: null,
        apply: false,
        qf: false,
        individual: data.mnemo,
        selected: 1,
        to: "", 
        from: "",
        fromToMode: false,
        ...data
      };

      if (index !== -1) {
        ___selectedFiltersCheckbox[index] = outlierFilterData

      } else {
        ___selectedFiltersCheckbox.push(outlierFilterData)
      }
    }
    if(item && item._mnemo && item._mnemo === "outlier" && selected === 0){
      const __mnemo = name.replace("outlier_", "");
      const index = ___selectedFiltersCheckbox.findIndex((doc)=>(doc.individual === __mnemo));
      ___selectedFiltersCheckbox.splice(index, 1);
    }

    if(___selectedFiltersCheckbox.length){
      ___selectedFiltersCheckbox.forEach((item)=>{
        selectedFiltersCheckbox.push(item);
      })
    }

    dispatch({
      type: "set-filters-checkbox",
      payload: selectedFiltersCheckbox,
    });

    // change 'checkboxTriggered' to false once the checkbox has been selected
    dispatch({ type: "reverse-checkbox", payload: false });
    window.scrollTo(0, 0);   
    handleAppliedCheckboxFilters(item,checkboxes)

    return {
      filterCheckboxes: selectedFiltersCheckbox,
      filterOptions: toggled_checkboxes.options_checkbox,
    };
  };

  
  // this function used to update appliedCheckbox filters to and/or logic
  const handleAppliedCheckboxFilters = (selectedFilter, checkBoxes) =>{ 
    const { name, selected , mnemo} = selectedFilter;
    const { appliedFilterCheckboxesList } = state.appliedFilterCheckboxes;

    if(selected === 1){
        let currentSelectedFilters = checkBoxes.filter(({key}) => key === mnemo)[0];
        let currentSelectedFiltersOptions = currentSelectedFilters?.options?.filter((filter) => filter.name === name)[0]
        let id = uuidv4()
        let newFilter = {
            id,
            title: currentSelectedFilters['title'],
            options: [{...currentSelectedFiltersOptions}]
        }

        // For Outlier filter
        if(mnemo === "outlier"){
          const isOutlierExist = appliedFilterCheckboxesList.map(item => {
            return item.options.some(option => (option.name === name));
          }).some(option => (option === true));
          if(isOutlierExist){

            let _updatedData = appliedFilterCheckboxesList.map(item => {
              const updatedOptions = item.options.map(option => {
                  if (option.name === name) {
                      return { ...option, ...{selected} };
                  }
                  return option;
              });
          return { ...item, options: updatedOptions };
          });

            dispatch({
              type: "applied-checkbox-filter-update",
              payload: {
                data: _updatedData,
                isMergingFilters: false
              }
            })

          } else{
            dispatch({
              type: "applied-checkbox-filter",
              payload: newFilter
            })
          }

        }else{
          dispatch({
            type: "applied-checkbox-filter",
            payload: newFilter
          })
        }
    }

    if(selected === 2){
      let updatedData = appliedFilterCheckboxesList.map(item => {
                              const updatedOptions = item.options.map(option => {
                                  if (option.name === name) {
                                      return { ...option, ...{selected} };
                                  }
                                  return option;
                              });
                          return { ...item, options: updatedOptions };
                        });
      dispatch({
        type: "applied-checkbox-filter-update",
        payload: {
          data: updatedData,
          isMergingFilters: false
        }
      })
    }

    if(selected === 0){
        // when we remove filter which is applied when visit this page from interventions and contributors 
        if(location?.state?.uititle && (info?.abbr === name)){
            updateLocationData();
        }
        const updatedData2 = appliedFilterCheckboxesList.map(item => {
                              const updatedTtem = {...item, options: item.options.filter(option => option.name !== name)};
                              return updatedTtem.options.length > 0 ? updatedTtem : null;
                          }).filter(item => item !== null);
        dispatch({
          type: "applied-checkbox-filter-update",
          payload: {
            data: updatedData2,
            isMergingFilters: false
          }
        })
      }
  } 

  const handleChangeNLPFilter = (filterName) => {
    const nlp_filter = state.nlp_filter;
    console.log("nlp_filter ", nlp_filter);
    const new_nlp_filter = {};
    Object.keys(nlp_filter).forEach((key) => {
      if (key === filterName) {
        new_nlp_filter[key] = { ...nlp_filter[key], disabled: false };
      } else if ((nlp_filter[filterName].value + 1) % 3 === 0) {
        new_nlp_filter[key] = { ...nlp_filter[key], disabled: false };
      } else {
        new_nlp_filter[key] = { ...nlp_filter[key], disabled: true };
      }
    });
    dispatch({ type: "disable-nlp-filter", payload: new_nlp_filter });
    dispatch({
      type: "set-nlp-filter",
      payload: {
        name: filterName,
        value: (state.nlp_filter[filterName].value + 1) % 3,
      },
    });

    // GA - Track the nlp filter
     event("patient_list_nlp_filters", {
       filter_name: filterName,
       filter_value:
         nlp_filter[filterName].value === 1 ? "Inderminate Selection" : "Selected",
     });
  };


  const determineDisabilityStyle = () => {
    return !state.identifiers_checkbox["options_checkbox"].some((filter) =>
      filter.options.some((opt) => opt.selected)
    ) && !state.selectedFilters.length
      ? 
      {opacity: 0.5, cursor: "not-allowed",pointerEvents: 'none'}
      : 
      {opacity: 1,cursor: "auto",pointerEvents: 'auto'};
  };
  
  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
    setPageSize(1);
    resetGeospatialData();
    
    if (newValue === "map_view") {
      // GA - Track click on Export ICS Codes Data
      event("view_geospatial", {});
    }
  };

  const setSideBarPageTitle = () =>{
    let title = location?.state?.uititle || `All Active ${toPluralize(patientSetInfo.patient_name)}`;
    return title
  }

  const updateLocationData = () =>{
      const currentLocation = location?.pathname;
      history({...currentLocation, state: undefined});
      setInfo({...info, abbr: ''})
  }

  const resetGeospatialData = () =>{
    dispatch({
      type: "set-geo-spatial",
      payload: {
        coordinates: [],            
        markerList: [],
        count: 0
      },
    });
  }

  return (
    <Sidebar
      pageTitle={setSideBarPageTitle()}
    >
      <div
        className="container-fluid app-bg-color p-4 patient-list-main-container"
        style={{ minHeight: "100vh" }}
      >
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Patient List View" value="list_view" />
              <Tab label="Geospatial Map View" value="map_view" />
            </TabList>
          </Box>

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              overflow: "auto",
            }}
          >
            <Container
              maxWidth="fullWidth"
              className="patient-list-inner-container"
            >
              <Paper className="patient-list-paper-container">
                <Grid container rowSpacing={1.5} pt={0}>
                  <Grid
                    container
                    item
                    justifyContent="space-between"
                    p={2}
                    pb={0}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        size={"large"}
                        onClick={toggleDrawer("left", true)}
                        startIcon={
                          <Avatar
                            sx={{ width: 19, height: 19 }}
                            src={saveIcon}
                          />
                        }
                      >
                        Advanced Filters
                      </Button>
                      <AdvancedFiltersList
                        open={sstate["left"]}
                        toggleDrawer={toggleDrawer}
                        onChange={handleChange}
                        info={info}
                        options={options}
                        setOptions={setOptions}
                        handleChangeNLPFilter={handleChangeNLPFilter}
                        fetchData={fetchData}
                      />
                    </Grid>
                    <Grid item>
                      <ColumnFilter />
                    </Grid>
                  </Grid>
                 <Grid
                    container
                    item
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    p={2}
                  >
                    <Grid item xs={10}>
                        <AppliedFilterList
                          dropdownFilterKey={filterKey}
                          removeFilter={handleChange}
                          sstate={sstate}
                        />
                    </Grid>
                    {currentTab === 'list_view' && 
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Tooltip
                        placement="top"
                        title="Save filters for comparisons and for patients sets where membership changes over time"
                        arrow
                      >
                        <Button
                          size={"large"}
                          variant="outlined"
                          onClick={(e) => {
                            setOpenSaveFilter(true);
                          }}
                          endIcon={
                            <Avatar
                              sx={{ width: 19, height: 19 }}
                              src={saveIcon}
                            />
                          }
                          sx={determineDisabilityStyle()}
                        >
                          Save Filters
                        </Button>
                      </Tooltip>
                    </Grid>
                  }
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      <TabPanel value="list_view" sx={{ padding: 0 }}>
                        <FilterTable
                          setKey={setKey}
                          fetchData={fetchData}
                          custAttrVal={custAttrVal}
                          fetchingTotalCount={fetchingTotalCount}
                        />
                      </TabPanel>
                      <TabPanel value="map_view" sx={{ padding: 0 }}>
                        <HeatMap loading={state.isFetching} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <SaveFilter open={openSaveFilter} setOpen={setOpenSaveFilter} />
            </Container>
          </Box>
        </TabContext>
      </div>
    </Sidebar>
  );
};

export default withRouter(PatientsList);
