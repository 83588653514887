import React, { useState, useContext, useEffect } from "react";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Histogram from "../Stats/Histogram";
import Skeleton from "@mui/lab/Skeleton";
import services from "../../Services/patientServices";
import closeIcon from "../../Assets/Images/close.png";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { toPluralize } from "../../Utils/stringOprations";
import { addJoinTypeKeyInFilters, deepClone } from "../../Utils/filterPatientList";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "17px",
  },
  subtitle: {
    fontSize: "15px",
    color: "rgba(0, 0, 0, 0.54)",
    marginLeft: "3px",
  },
  section: {
    marginTop: "5px",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 15px 0px",
  },
  backdrop: {
    zIndex: 500,
    color: "#78cbf2",
  },
  table: {
    minWidth: 500,
    border: "none",
  },
  root: {
    width: "90%",
    marginLeft: "auto",
    marginRight: 'auto',
    marginTop: "20px"
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    border: "none",
  },
  head: {
    backgroundColor: "#ececec",
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: "#fff",
  },
}))(TableRow);

const FilterMenu = ({ keyName, entity, anchorEl, setAnchorEl }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { state } = useContext(DropdownFilterContext);
  const { patientSetInfo } = useContext(PatientSetContext);

  const [statsData, setStatsData] = useState(null);
  const [histogramData, setHistogramData] = useState({ histoCount: [], histoBins: null  });
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = anchorEl ? "simple-popover" : undefined;

  const fetchData = async () => {
    try {
      setIsFetching(true);
      const { selectedFilters, selectedFiltersCheckbox, nlp_filter } = state;
      const { selected_date, patient_set_id } = patientSetInfo;
      const filters = selectedFilters.concat(selectedFiltersCheckbox);
      let filters2 = addJoinTypeKeyInFilters(
        deepClone(state?.appliedFilterCheckboxes?.appliedFilterCheckboxesList),
        deepClone(filters)
      );
      const body = {
        pid: patient_set_id,
        filters: filters2,
        selected_date,
        column: keyName,
        nlp_filter: Object.assign(...Object.keys(nlp_filter).map((k) => ({ [k]: nlp_filter[k].value })))
      };
      const { data } = await services.patientStats(body);
      setStatsData(data.data);
      setHistogramData(formatHistogramData(data.histogram));
      setIsFetching(false);
    }
    catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  const getSum = (array)=>{
    console.log(array)
    if(!array) return
    return array.reduce((prev, curr)=> prev + curr, 0)

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const formatHistogramData = (data) => {
    const histoCount = [{ data: [] }];    
    const histoBins = { xaxis: { categories: [] } };
    data.forEach(item => {
      histoCount[0].data.push(parseInt(parseInt(item.bin_count)));
      if(item.bin_floor.includes('>')) {
        histoBins.xaxis.categories.push(item.bin_floor);
      }
      else {
        histoBins.xaxis.categories.push(Number(item.bin_floor.replace('>', '')));
      }
    });
    console.log("sum of array", getSum(histoCount[0].data))
    return { histoCount, histoBins };
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="stats-modal-popper">
          <div className="patient-set-body">
            <div className={classes.section}>
              <Grid item xs>
              <div className="flex-sb">
                <Typography className={classes.title} gutterBottom variant="h6">
                    <span className={classes.subtitle}>{entity} Statistics (For {" "}
                      {state.totalCount}/{localStorage.getItem('count')} {" "}
                      {toPluralize(patientSetInfo.patient_name)})</span>
                  </Typography>
                  <div>
                    <img
                      className="close-icon"
                      src={closeIcon}
                      alt="close icon"
                      onClick={handleClose}
                    />
                  </div>
                  </div>
              </Grid>
            </div>
            <div>
              {isFetching ? (
                <div className={classes.root}>
                  <Skeleton className="mt-2" variant="rect" height={220} />
                  <Skeleton className="mt-4" variant="rect" height={25} animation={false} />
                  <Skeleton className="mt-2" variant="rect" height={25} animation={false} />
                </div>
              ) : (
                <div>
                  <div className="stats-histogram-container">
                    <Histogram  
                      className="stats-histogram" 
                      entity={entity}
                      histoSeries={histogramData.histoCount}
                      histoOptions={histogramData.histoBins} 
                    />
                  </div>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">Min</StyledTableCell>
                            <StyledTableCell align="center">Max</StyledTableCell>
                            <StyledTableCell align="center">
                              Median
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Average
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Non-Null Values
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Null Values
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {statsData ? (
                            <StyledTableRow>
                            <StyledTableCell align="center">
                              {statsData.min}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {statsData.max}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {statsData.median}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {statsData.average}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {statsData.count_not_null}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {statsData.count_null}
                            </StyledTableCell>
                          </StyledTableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default FilterMenu;
