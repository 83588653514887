import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import services from "../../../Services/patientServices";
import lineImgGreen from "../../../Assets/Images/chart-line-green.png";
import lineImgRed from "../../../Assets/Images/chart-line-red.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  TabSkeletonLoader2,
} from "../../../Components/SkeletonLoader/DashboardSkeleton";


const DIRECTION = {
  up: {
    arrow: <ArrowDropUpIcon />,
    color: "#0AB27D",
    graph: lineImgGreen,
  },
  down: {
    arrow: <ArrowDropDownIcon />,
    color: "#FA0607",
    graph: lineImgRed,
  },
  none: {
    arrow: "",
    color: "#FA0607",
    graph: lineImgRed,
  },
};

const CohortOverview = ({ cohort_id, selectedTimeFrame }) => {
  const [overViewData, setOverViewData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getCohortOverViewData = async (cohort_id, timeFrame) => {
    try {
      setIsFetching(true);
      const result = await services.cohortOverView({
        cohort_id: cohort_id,
        timeframe: timeFrame
      });
      const data = result.data.data;
      if (data?.length) {
        const filteredData = data.map((cohort) => {
          if (cohort.counts.length) {
            cohort.counts = cohort.counts.filter((i) => i.data.total);
          }
          return cohort;
        });
        setOverViewData(filteredData);
      }
    } catch (error) {
      console.log("Error while fetching cohort overview", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if(cohort_id){
      getCohortOverViewData(cohort_id, selectedTimeFrame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohort_id, selectedTimeFrame]);

  if (isFetching) return <TabSkeletonLoader2 />;

  return (
    <>
      {overViewData.map(
        (tab, index) =>
          tab.cohort_id === cohort_id && (
            <Grid container spacing={2} justifyContent={"space-between"} key={index}>
              {tab.counts.map((count) => (
                <Grid
                  item
                  lg={
                    tab.counts.length > 4
                      ? 2.4
                      : tab.counts.length > 3
                      ? 3
                      : tab.counts.length > 2
                      ? 4
                      : 6
                  }
                  md={6}
                  xs={12}
                >
                  <Card sx={{ width: "100%", height: 125 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14, lineHeight: 1.5, pb: "2px" }}
                        color="text.secondary"
                      >
                        {count.title}
                      </Typography>
                      <Typography variant="h5" fontWeight={700} component="div">
                        {count.data.total}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={DIRECTION[count.data.direction]["color"]}
                        display={"flex"}
                      >
                        {DIRECTION[count.data.direction]["arrow"]}
                        {count.data.changes + "%"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )
      )}
    </>
  );
};

export default CohortOverview;
