import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MissingCode from "./MissingCode";
import RecommendServices from "./RecommendedServices";
import IpErLabResultsTab from "./IpErLabResultsTab";
import SdohData from "./SdohData";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    width: "100%",
    height: "400px",
    overflow: "hidden",
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const SmartAlertTabs = ({ value, setValue, selectedNotification }) => {
  const classes = useStyles();  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.mainContainer} id="problems-identified">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#fff"}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">            
            <Tab
              label="SDoH"
              {...a11yProps(0)}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Missing Code"
              {...a11yProps(1)}
              disabled
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Recommended Services"
              {...a11yProps(2)}
              disabled
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="IP/ER Visit/Lab results "
              {...a11yProps(3)}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={1}>
          <MissingCode />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={0}>
          <SdohData />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <RecommendServices />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <IpErLabResultsTab selectedNotification={selectedNotification} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export default SmartAlertTabs;
