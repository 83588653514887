import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ComparisonChart from "../../Components/Charts/ComparisonChart";
import { useEffect } from 'react';
import services from "../../Services/patientServices";
import { Skeleton } from '@mui/material';
import ProviderBarChart from './ProviderBarChart';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ height: "50px", fontSize: "16px", fontWeight: "600" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ComparisonStats = ({ open, setOpen, timeframe, selectedChart, datafilters, providerChartData, type, benchmark }) => {
  const [chartData, setChartData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const getChartData = async () => {
    const chartResult = await services.comparisonStatsChart({ timeframe, column: selectedChart.key });
    const data = chartResult.data.data

    const datafiltersMap = {};
    // Populate the datafiltersMap with cohort_id with show values
    datafilters.forEach(filter => {
      datafiltersMap[filter.cohort_id] = filter.show;
    });

    // Filter the data array based on the cohort_id show values in datafiltersMap
    const filterdata = data.filter(i => datafiltersMap[i.cohort_id]);
    setChartData(filterdata);
    setIsFetching(false);
  };

  useEffect(() => {
    if (type !== 'barchart')
      getChartData();
    else
      setIsFetching(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {selectedChart.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {!isFetching ? (type === 'barchart' ?
            <ProviderBarChart chartData={providerChartData} selectedChart={selectedChart} benchmark={benchmark} /> :
            <ComparisonChart chartData={chartData} selectedChart={selectedChart} />
          ) : (
            <>
              <Skeleton variant="rectangular" height={50} sx={{ margin: "10px" }} />
              <Skeleton variant="rectangular" height={50} sx={{ margin: "10px" }} />
              <Skeleton variant="rectangular" height={50} sx={{ margin: "10px" }} />
              <Skeleton variant="rectangular" height={50} sx={{ margin: "10px" }} />
              <Skeleton variant="rectangular" height={50} sx={{ margin: "10px" }} />
            </>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ComparisonStats;
