import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import services from "../../../Services/patientServices";
import Spinner from "../../../Components/Spinner/Spinner";
import { PatientSetContext } from "../../../Contexts/patientSetContext";

const CohortComparisonChart = ({
  selectedChart,
  selectedProgram,
  timeframe,
  tableData
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const { patientSetInfo } = useContext(PatientSetContext);

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, selectedProgram]);

  const formatChartData = (chartData) => {
    const selectedKey = selectedChart.key;
    const data = chartData;

    let selectedIndex = data.findIndex(
      (obj) => obj.cohort_id === selectedProgram
    );

    if (selectedIndex !== -1) {
      let selectedObject = data.splice(selectedIndex, 1)[0];
      data.unshift(selectedObject);
    }

    const formattedData = data.map((cohort) => {
      return {
        name: cohort.cohort_name,
        data: cohort[selectedKey]
        ? Object.values(cohort[selectedKey]).map((item) => item).reverse()
        : [],
      };
    });
    setSeriesData(formattedData);

    const newData = chartData
      .filter((d) => d[selectedKey])
      .reduce((acc, d) => {
        const keys = Object.keys(d[selectedKey]);
        keys.length > acc.length && (acc = keys);
        return acc;
      }, []);

    const labels = newData.length ? newData.map((label) => (`${label}`)).reverse() : [];
    setXLabels(labels);
  };

  const getChartData = async () => {
    setIsFetching(true);
    if (patientSetInfo) {
      const { patient_set_id, name } = patientSetInfo;

      const chartResult = await services.comparisonStatsChart({
        cohortData: [{ patient_set_id, name, cohort_labels: null }],
        timeframe,
        column: selectedChart.key,
      });
      const data = chartResult.data.data || [];
      formatChartData(data);
    } else {
      formatChartData([]);
    }
    setIsFetching(false);
  };

  const formatLabels = (count) => {
    const type = selectedChart.format;

    switch (type) {
      case "comma-separated":
        return Number(count).toLocaleString();
      case "decimal-fixed":
        return Number(count).toFixed(2);
      case "currency-string":
        return "$" + Number(count).toLocaleString();
      case "currency-string-million":
        return (
          "$" +
          (count / 1000000).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) +
          "M"
        );
      default:
        return "-";
    }
  };

  const chartOptions = {
    chart: {
      id: "comparison-chart",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#0C66E4", "#5F5F5F", "#D6CFC7", "#B9BBB6", "#D9DDDC", "#97978F", "#5F5F5F", "#D6CFC7", "#B9BBB6", "#D9DDDC", "#97978F"],
    xaxis: {
      categories: xLabels,
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        rotate: -30,
        rotateAlways: true,
      }
    },
    yaxis: {
      labels: {
        formatter: formatLabels,
      },
    },
    stroke: {
      width: 1,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "#fff"],
        opacity: 0.8,
      },
    },
    markers: {
      size: [3, 3],
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      markers: {
        width: 14,
        height: 14,
        radius: 0,
      },
    },
  };

  return (
    <div className="comparison-chart">
      {isFetching ? (
        <Spinner />
      ) : (
        <Chart
          options={chartOptions}
          series={seriesData}
          type="line"
          height={350}
        />
      )}
    </div>
  );
};

export default CohortComparisonChart;
