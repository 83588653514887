import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { Box, Grid, Skeleton, Typography } from "@mui/material";
import "./WcchcPatientSummary.scss";
import Logo from "../../Assets/Images/logo-1.png";
import LogoText from "../../Assets/Images/logo-text-1.png";
// import ShareIcon from "../../Assets/Images/share-blue.png";

// components
import ProblemsIdentified from "../../Components/WcchcPatientSummary/ProblemsIdentified";
import Medications from "../../Components/WcchcPatientSummary/Medications";
import RecentLabs from "../../Components/WcchcPatientSummary/RecentLabs";
import HealthTrends from "../../Components/WcchcPatientSummary/HealthTrends";
import RiskCard from "../../Components/WcchcPatientSummary/RiskCard";
import PatientDetailsCard from "../../Components/WcchcPatientSummary/PatientDetailsCard";
// import SmartAlertsCard from "../../Components/WcchcPatientSummary/SmartAlertsCard";
import SdohAnalysisCard from "../../Components/WcchcPatientSummary/SdohAnalysisCard";
import HospitalAdmitsAndErVisit from "../../Components/WcchcPatientSummary/HospitalAdmitsAndErVisit";
import Referrals from "../../Components/WcchcPatientSummary/Referrals";

// modals
import ProblemsIdentifiedModal from "../../Components/Modal/WcchcDashboard/ProblemsIdentifiedModal";
import MedicationsModal from "../../Components/Modal/WcchcDashboard/MedicationsModal";
import ReferralsModal from "../../Components/Modal/WcchcDashboard/ReferralsModal";
import HospitalAdmitsAndErVisitModal from "../../Components/Modal/WcchcDashboard/HospitalAdmitsAndErVisitModal";
import RecentLabsModal from "../../Components/Modal/WcchcDashboard/RecentLabsModal";
import ComprehensiveRiskScoreModal from "../../Components/Modal/WcchcDashboard/ComprehensiveRiskScoreModal";
import SdohAnalysisModal from "../../Components/Modal/WcchcDashboard/SdohAnalysisModal";
import SmartAlertsModal from "../../Components/Modal/WcchcDashboard/SmartAlertsModal";

//services
import wcchcServices from "../../Services/wcchcServices";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
// import NotificationPopOver from "../../Components/WcchcPatientSummary/NotificatonPopOver";

// Patients Data
import { ORG_ID, PATIENTS_DATA_UAT, PATIENTS_DATA_DEV, PATIENTS_DATA_DEMO } from "../../constants";
import PatientDetailsModal from "../../Components/Modal/WcchcDashboard/PatientDetailsModal";
import Error from "../Error/Error";
// import NotificationPopOver from "../../Components/WcchcPatientSummary/NotificatonPopOver";
// import SmartAlertDemo from "../../Components/WcchcPatientSummary/SmartAlertDemo";

const WcchcPatientSummary = () => {
  const history = useNavigate();
  const environment = window.config.environment;
  const { id } = useParams();
  const { dispatch, state } = useContext(WcchcPatientSummaryContext);

  // const [currentSelectedNotification, setCurrentSelectedNotification] = useState("");
  // health trends
  const [selectedDisease, setSelectedDisease] = useState("vitals");
  const [duration, setDuration] = useState("12");
  const [labResultsDuration, setlabResultsDuration] = useState("12");
  // const [value, setValue] = useState(0);
  // const [selectedNotification, setSelectedNotification] = useState({});
  // const [sdohDuration, setSdohDuration] = useState("12");

  // modal values
  const [problemsIdentifiedModal, setProblemsIdentifiedModal] = useState(false);
  const [medicationsModal, setMedicationsModal] = useState(false);
  const [referralsModal, setReferralsModal] = useState(false);
  const [hospitalAdmitsAndErVisits, setHospitalAdmitsAndErVisits] = useState(false);
  const [recentLabModal, setRecentLabModal] = useState(false);
  const [healthTrendsModal, setHealthTrendsModal] = useState(false);
  const [comprehensiveRiskModal, setComprehensiveRiskModal] = useState(false);
  // const [sdohModal, setSdohModal] = useState(false);
  const [patientDetailsModal, setPatientDetailsModal] = useState(false);
  const [sdohAnalysisModal, setSdohAnalysisModal] = useState(false);
  const [smartAlertsModal, setSmartAlertsModal] = useState(false);

  //medication attentive value
  const [medicationAttentive, setMedicationAttentive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const identifierIdRef = useRef(null);

  const handleHealthTrendsModal = () => {
    setHealthTrendsModal(!healthTrendsModal);
    // setDuration("12");
  };

  const handleLabResultsModal = () => {
    setRecentLabModal(!recentLabModal);
  };

  // const handleSdohModal = () => {
  //   setSdohModal(!sdohModal);
  // };

  // const handleNotificationClick = (type) => {
  //   switch (type) {
  //     case "problems-identified":
  //       setCurrentSelectedNotification("problems-identified")
  //       break;
  //     case "medications":
  //       setCurrentSelectedNotification("medications")
  //       break;
  //     case "referrals":
  //       setCurrentSelectedNotification("referrals")
  //       break;
  //     case "visits":
  //       setCurrentSelectedNotification("referrals")
  //       break;
  //     case "lab-results":
  //       setCurrentSelectedNotification("lab-results")
  //       break;
  //     case "health-trends":
  //       setCurrentSelectedNotification("health-trends")
  //       break;
  //     case "comprehensive-risk":
  //       setCurrentSelectedNotification("comprehensive-risk")
  //       break;
  //     case "sdoh":
  //       setCurrentSelectedNotification("sdoh")  
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleFetchError = (error) => {
    const errorMessage = error?.response?.data?.message;
    console.log("Error wcchc summary:", errorMessage);
    if (errorMessage === "Error fetching patient ID.") {
      let msg = `No analytics available for this patient because patient MRN ${identifierIdRef.current} is not known to the analytics backend.`
      setIsError(true);
      setErrorMessage(msg);
    }
  };


  // To-Do
  const fetchLabResults = async (identifier_id, org_id) => {
    try {
      const labResults = await wcchcServices.getLabResults(identifier_id, org_id);
      dispatch({
        type: "LAB_RESULTS",
        payload: { labResults: labResults.data.labResults },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  // const fetchAlertNotifications = async (identifier_id, org_id) => {
  //   const alertNotifications = await wcchcServices.getAlertNotifications(identifier_id, org_id);
  //   dispatch({
  //     type: "ALERT_NOTIFICATIONS",
  //     payload: { alertNotifications: alertNotifications.data.notifications },
  //   });
  // };

  // const getAllSmartAlerts = async (identifier_id, org_id) => {
  //   const allSmartAlerts = await wcchcServices.getAllSmartAlerts(identifier_id, org_id);
  //   dispatch({
  //     type: "LIST_SMART_ALERTS",
  //     payload: { allSmartAlerts: allSmartAlerts.data.notifications },
  //   });
  // };

  const fetchMedications = async (identifier_id, org_id) => {
    try {
      const medications = await wcchcServices.medications(identifier_id, org_id);
      dispatch({
        type: "MEDICATIONS",
        payload: { medications: medications.data.medications },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchVitals = async (identifier_id, org_id) => {
    try {
      const vitals = await wcchcServices.vitals(identifier_id, org_id);
      dispatch({
        type: "VITALS",
        payload: {
          vitals: vitals.data.vitalInformation,
          healthTrends: vitals.data.healthTrends,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }

  };

  const fetchProblemsIdentified = async (identifier_id, org_id) => {
    try {
      const problemsIdentified = await wcchcServices.problemsIdentified(
        identifier_id,
        org_id
      );
      dispatch({
        type: "PROBLEMS_IDENTIFIED",
        payload: {
          problemsIdentified: problemsIdentified.data.problemIdentified,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchComprehensiveRisk = async (identifier_id, org_id) => {
    try {
      const comprehensiveRiskScore = await wcchcServices.comprehensiveRisk(
        identifier_id,
        org_id
      );
      dispatch({
        type: "COMPREHENSIVE_RISK_SCORE",
        payload: {
          comprehensiveRiskScore: comprehensiveRiskScore.data.comprehensiveRisk,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchPatientDetails = async (identifier_id, org_id) => {
    try {
      const patientDetails = await wcchcServices.patientDetails(
        identifier_id,
        org_id
      );
      dispatch({
        type: "PATIENT_DETAILS",
        payload: {
          patientDetails: patientDetails.data.patientDetails,
        },
      });
    } catch (error) {
      handleFetchError(error);
  };
}

  const fetchVisits = async (identifier_id, org_id) => {
    try {
      const visits = await wcchcServices.patientVisits(
        identifier_id,
        org_id
      );
      dispatch({
        type: "PATIENT_VISITS",
        payload: {
          visits: visits.data.visits,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchSdoh = async (identifier_id, org_id) => {
    try {
      const sdohAnalysis = await wcchcServices.sdohAnalysis(
        identifier_id,
        org_id
      );
      dispatch({
        type: "SDOH_ANALYSIS",
        payload: {
          sdohAnalysis: sdohAnalysis.data.sdohAnalysis,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchSmartAlerts = async (identifier_id, org_id) => { 
    try {
      const smartAlerts = await wcchcServices.smartAlerts(
        identifier_id,
        org_id
      );
      dispatch({
        type: "SMART_ALERTS",
        payload: {
          smartAlerts: smartAlerts.data.alerts,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchReferrals = async (identifier_id, org_id) => { 
    try {
      const referralsData = await wcchcServices.referrals(
        identifier_id,
        org_id
      );
      dispatch({
        type: "REFERRALS",
        payload: {
          referrals: referralsData.data.referralsResults,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  const fetchWidgetUpdateDate = async (widget_name) => { 
    try {
      const widgetUpdateData = await wcchcServices.getWidgetUpdatedDate(
        widget_name
      );
      dispatch({
        type: "WIDGET_LAST_DATE_UPDATE",
        payload: {
          last_updated_date: widgetUpdateData?.data?.widget_updated_date?.updated_date,
        },
      });
    } catch (error) {
      handleFetchError(error);
    }
  };

  useEffect(() => {
    if (id) {
      let identifier_id = null,
        org_id = ORG_ID[environment];
      if (environment === "uat") {
        const patient = PATIENTS_DATA_UAT.find((doc) => doc.id === Number(id));
        identifier_id = patient ? patient.patientId : null;
      }
      
      if (environment === "production") {
        identifier_id = id;
      }

      if (environment === "dev") {
        const patient = PATIENTS_DATA_DEV[0];
        identifier_id = patient ? patient.patientId : null;
      }

      if (environment === "demo-a" || environment === "demo") {
        const patient = PATIENTS_DATA_DEMO[0];
        identifier_id = patient ? patient.patientId : null;
      }
  
      if (identifier_id) {
        identifierIdRef.current = identifier_id;
        dispatch({ type: "RESET" });
        fetchLabResults(identifier_id, org_id);
        // fetchAlertNotifications(identifier_id, org_id);
        // getAllSmartAlerts(identifier_id, org_id);
        fetchMedications(identifier_id, org_id);
        fetchVitals(identifier_id, org_id);
        fetchProblemsIdentified(identifier_id, org_id);
        fetchComprehensiveRisk(identifier_id, org_id);
        fetchPatientDetails(identifier_id, org_id);
        fetchVisits(identifier_id, org_id);
        fetchSdoh(identifier_id, org_id);
        fetchSmartAlerts(identifier_id, org_id);
        fetchReferrals(identifier_id, org_id);
        fetchWidgetUpdateDate("lab_results")
        // fetchPatientSummary(identifier_id, org_id);
      } 
    } else {
      history("/wcchc/dashboard/1");
    }
    //to-do pass patient id(identifier-id) in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  
  if (isError) {
    return <Error message={errorMessage} ehr={true}/>;
  }

  return (
    <Box className="patient-summary-container" id="patient-summary-container">
      <Box className="main-container">
        <Box className="header-container">
          <div>
            <img src={Logo} alt="summary-logo" />
            <img src={LogoText} alt="summary-logo-text" />
          </div>
          <Box className="header-right">
            <Box className="last-update-box">
              <Typography className="detail-text" sx={{ marginRight: "3px" }}>
                Last Updated On:
              </Typography>
              {state.isFetchingLastUpdatedDate ? <Skeleton variant="rounded" sx={{width: "100px"}} height={25}/> : <Typography className="detail-text">{state.lastUpdatedDate}</Typography>}
            </Box>
            {/* <NotificationPopOver
              notificationsData={state.alertNotifications}
              isFetchingAlert={state.isFetchingAlertNotifications}
              identifier_id={identifierIdRef.current} 
              org_id={ORG_ID[environment]}
              refreshAlerts={fetchAlertNotifications}
              setValue={setValue}
              setSelectedNotification={setSelectedNotification}
            /> */}
          </Box>
        </Box>
        <Box className="patient-summary-details-container">
            <Box className="patient-details-container">
              <PatientDetailsCard setPatientDetailsModal={setPatientDetailsModal} />
            </Box>
            <Box className="risk-section-main-container">
              <Box className="left-details">
                <RiskCard 
                  setComprehensiveRiskModal={setComprehensiveRiskModal} 
                  // showBorder = {currentSelectedNotification === "comprehensive-risk" ? true: false}
                />
              </Box>
            </Box>
            {/* <Box className="summary-container">
              <SmartAlertDemo value={value} setValue={setValue} selectedNotification={selectedNotification}  />
            </Box> */}
            <Box className="grid-container">
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <Box className="grid-container-left">
                    <ProblemsIdentified
                      setProblemsIdentifiedModal={setProblemsIdentifiedModal}
                      // showBorder = {currentSelectedNotification === "problems-identified" ? true : false }
                    />
                    <Medications
                      attentive={medicationAttentive}
                      setAttentive={setMedicationAttentive}
                      setMedicationsModal={setMedicationsModal}
                      // showBorder = {currentSelectedNotification === "medications" ? true : false }
                    />
                    <HospitalAdmitsAndErVisit
                      setHospitalAdmitsAndErVisits={setHospitalAdmitsAndErVisits}
                      // showBorder = {currentSelectedNotification === "referrals" ? true : false }
                    />
                  </Box>
                </Grid>
                <Grid item md={6} sm={12}>
                  <Box className="grid-container-right">
                    {/* <SmartAlertsCard 
                      setSmartAlertsModal={setSmartAlertsModal}
                    /> */}
                    <HealthTrends
                      handleHealthTrendsModal={handleHealthTrendsModal}
                      selectedDisease={selectedDisease}
                      setSelectedDisease={setSelectedDisease}
                      duration={duration}
                      setDuration={setDuration}
                      open={healthTrendsModal}
                      // showBorder = {currentSelectedNotification === "health-trends" ? true : false }
                    />
                    <RecentLabs
                      handleModal={handleLabResultsModal}
                      duration={labResultsDuration}
                      setDuration={setlabResultsDuration}
                      open={recentLabModal}
                      // showBorder = {currentSelectedNotification === "lab-results" ? true : false }
                    />
                    <Referrals 
                        setReferralsModal={setReferralsModal}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="s">
              <SdohAnalysisCard 
                setSdohAnalysisModal={setSdohAnalysisModal}
              />
            </Box>
        </Box>
        {problemsIdentifiedModal && (
          <ProblemsIdentifiedModal
            open={problemsIdentifiedModal}
            setProblemsIdentifiedModal={setProblemsIdentifiedModal}
          />
        )}
        {medicationsModal && (
          <MedicationsModal
            open={medicationsModal}
            setMedicationsModal={setMedicationsModal}
            setAttentive={setMedicationAttentive}
          />
        )}
        {referralsModal && (
          <ReferralsModal
            open={referralsModal}
            setReferralsModal={setReferralsModal}
          />
        )}
        {hospitalAdmitsAndErVisits && (
          <HospitalAdmitsAndErVisitModal
            open={hospitalAdmitsAndErVisits}
            setHospitalAdmitsAndErVisits={setHospitalAdmitsAndErVisits}
          />
        )}
        {recentLabModal && (
          <RecentLabsModal
            open={recentLabModal}
            handleModal={handleLabResultsModal}
            duration={labResultsDuration}
          />
        )}
        {comprehensiveRiskModal && (
          <ComprehensiveRiskScoreModal
            open={comprehensiveRiskModal}
            setComprehensiveRiskModal={setComprehensiveRiskModal}
          />
        )}
        {
          patientDetailsModal && (
            <PatientDetailsModal
              open={patientDetailsModal}
              setPatientDetailsModal={setPatientDetailsModal}
            />
          )
        }
        {
          sdohAnalysisModal && (
            <SdohAnalysisModal
              open={sdohAnalysisModal}
              setSdohAnalysisModal={setSdohAnalysisModal}
            />
          )
        }
        {
          smartAlertsModal && (
            <SmartAlertsModal
              open={smartAlertsModal}
              setSmartAlertsModal={setSmartAlertsModal}
            />
          )
        }
      </Box>
    </Box>
  );
};

export default WcchcPatientSummary;
