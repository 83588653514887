const hexToRgb = (hex) => {
    // Remove the hash (#) character if present
    hex = hex.replace(/^#/, '');
    // Parse the hex values for red, green, and blue components
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  

export const generateColor = (percentage) =>{
      // Ensure the percentage is within the valid range (0 to 100)
      const clampedPercentage = Math.min(100, Math.max(0, percentage));
      // Convert hex values to RGB
      const color1 = hexToRgb('E33E33');
      const color2 = hexToRgb('B5B85D');
      // Interpolate the color between the two hex colors
      const r = Math.round((color1.r * (100 - clampedPercentage) + color2.r * clampedPercentage) / 100);
      const g = Math.round((color1.g * (100 - clampedPercentage) + color2.g * clampedPercentage) / 100);
      const b = Math.round((color1.b * (100 - clampedPercentage) + color2.b * clampedPercentage) / 100);
      // Return the CSS color string
      return `rgb(${r},${g},${b})`;
}

export const generateColor2 = (percentage) =>{
  if( percentage >= 0 && percentage <= 25){
    return '#FF0000'
  }else if( percentage > 25 && percentage <= 50){
    return '#C45508'
  }else if( percentage > 50 && percentage <= 75){
    return '#FFFF08'
  }else{
    return '#00FF00'
  }
}
