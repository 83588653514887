import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import "./GenesisIframe.scss";
import Filter from "../../Components/Genesis/Filter";
import genesisServices from "../../Services/genesisServices";
import { createNumberArray } from "../../Utils/createNumberArray";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {CustomTooltip} from "../../Components/Genesis/Tooltip";
import { generateColor2} from "../../Utils/generateColorForCohortChart";
import { cohortChartDateFormat } from "../../Utils/formatDate";
import ErrorPage from "./ErrorPage";
import LoaderWithTimer from "./LoaderWithTimer";
import ClientDetailsModal from "./ClientDetailsModal";
import { deepClone } from "../../Utils/filterPatientList";
import { addMissingMonths } from "../../Utils/addMissingMonths";

const options = [
  {id: 1, value: 'Download PNG', key: 'dwdpng'},
  {id: 2, value: 'Download CSV', key: 'dwdcsv'},
];


const useStyles = makeStyles((theme) => ({
  titleContainer: {
    borderBottom: "5px solid #eaeaea",
  },
  title: {
    color: "#e85e1e",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "10px",
    margin: "20px 0px",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    "&.th": {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  },
  verticalLegend: {
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    textAlign: "center",
    position: "absolute",
    left: 0,
    top: "50%",
  },
  mainLegendContainer: {
    marginBottom: "20px",
  },
  legendInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "50px",
  },
  gradientBar: {
    display: "inline-block",
    height: "20px",
    width: "100px",
    backgroundImage: "linear-gradient( to right, #ff0000 0%, #ff0000 25%, #00ff00 25%, #00ff00 50%, #0000ff 50%, #0000ff 75%,  #ffff00 75%,  #ffff00 100% );",
    borderRadius: "10px",
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    // fontSize: "14px"
  },
  lableContainer:{
    "& span":{
        padding: "5px 10px",
        fontSize: "12px"
    },
    "& span:nth-child(1)":{
      backgroundColor: "#FF0000"
    },
    "& span:nth-child(2)":{
      backgroundColor: "#C45508"
    },
    "& span:nth-child(3)":{
      backgroundColor: "#FFFF08"
    },
    "& span:nth-child(4)":{
      backgroundColor: "#00FF00"
    },
  },
  toolTip: {
    backgroundColor: "blue",
  },
  horizontalLegend:{
    alignSelf: 'center',
    marginBottom: "0px"
  }
}));

const CustomStyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#006DB0",
  },
});

const CohortAnalysisChart = () => {
  const classes = useStyles();
  const [cohortData, setCohortData] = useState([]);
  const [headerArrayData, setHeaderArrayData] = useState([]);
  const [treatmentType, setTreatmentType] = useState("All");
  const [learnerType, setLearnerType] = useState("learnerall");
  const [loader, setLoader] = useState(false);
  const [completeData, setCompleteData] = useState({});
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [minDate, setMinDate] = useState("")
  const [locationList, setLocationList] = useState([])
  const [location, setLocation] = useState("all_location");
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const [treatmentMonth, setTreatmentMonth] = useState("");
  const [aggregatedData, setAggregatedData] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onFromDateChange = (date) => {
    setFromDate(date);
  };

  const onToDateChange = (date) => {
    setToDate(date);
  };

  const handleSetTreatmentType = (value) => {
    setTreatmentType(value);
  };

  const handleLearnerTypeChange = (value) => {
    setLearnerType(value);
  };

  // Download Png file starts
  const downloadTableAsPNG = () => {
    const table = document.getElementById('cohort-table'); // Replace 'your-table-id' with the actual ID of your table
    if (!table) {
      console.error('Table not found');
      return;
    }

    html2canvas(table)
    .then((canvas) => {
      // Convert canvas to PNG image data
      const imageData = canvas.toDataURL('image/png');
      // Trigger download
      saveAs(imageData, 'cohort-analysis.png');
    })
    .catch((error) => {
      console.error('Error capturing table as image:', error);
    });
  };

  // Download Png file ends

  // Download CSV File starts
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) => Object.values(row).join(','));
    return `${header}\n${rows.join('\n')}`;
  };

  const exportToCSV = () => {
    let finalData=[]
    cohortData.forEach(({treatment_month,clientCount,data})=>{
      let newPara = {};
      newPara['treatment_month'] = treatment_month;
      newPara['clientCount'] = clientCount;
      
      data.forEach(({month_number,total_percentage}) =>{
        newPara[`month-${month_number}`] = total_percentage === '' ? " " : `${total_percentage}%`
      })
      finalData.push(newPara)
    })
    // to export aggregated data in csv
    let aggrPara={}
    const {clientCount, data: aggrData} = aggregatedData;
    aggrPara['treatment_month'] = "Total";
    aggrPara['clientCount'] = clientCount;
    aggrData.forEach(({total_percentage, month_number}) =>{
      aggrPara[`month-${month_number}`] = total_percentage === '' ? " " : `${total_percentage}%`
    })
    finalData.push(aggrPara)

    const csvData = convertToCSV(finalData);
    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    // Create a download link and trigger the download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download','cohort-analysis.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Download is not supported in this browser.');
    }
  };
  // Download CSV File ends

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnMenuChange = (value) =>{
    console.log("value",value);
    if(value === 'dwdpng'){
      downloadTableAsPNG()
    }else{
      exportToCSV()
    }
    setAnchorEl(null);
  }

  const handleLocationChange = (value) =>{
    setLocation(value)
  }

  const handleClientDetailsModal = (value,treatment_month,clientCount) =>{
    if(!clientCount) return
    setShowClientDetailsModal(value);
    setTreatmentMonth(treatment_month)
  }

  const chartDataConverter = (data) => {
    let numberArray;
    let finalData = data.map((rowData) => {
      const { treatment_month, C_h: clientCount, ...restData } = rowData;
      let length = Object.keys(restData).length / 3;
      numberArray = createNumberArray(length);
      let data2 = numberArray.map((item) => {
        return {
          met: restData[`met_${item}`] ?? "",
          total_objectives: restData[`total_objectives_${item}`] ?? "",
          total_percentage: restData[`total_per_${item}`] ?? "",
          month_number: item,
        };
      });
      return {
        treatment_month,
        clientCount,
        data: data2      
      };
    });
    let finalDataAfterAddingMissingMonths = addMissingMonths(deepClone(finalData));
    return {
      finalData: [...finalDataAfterAddingMissingMonths],
      numberArray: [...numberArray],
    };
  };

  const applyFilter = () => {
    let data = { ...completeData[location] };
    let selectedLearnerType = learnerType;
    let data2 = data[selectedLearnerType];
    if(!data2 || Object.keys(data2).length === 0){
      setCohortData([])
      return
    }

    let data3 = data2[treatmentType]
    console.log("******cohort analysis data3", data3);
    if(!data3 || data3.length === 0){
      setCohortData([])
      return
    }

    const { finalData, numberArray } = chartDataConverter([...data3]);
    let filteredData = [...finalData];

    if (fromDate) {
      let fromDateSelected = new Date(fromDate).setHours(0, 0, 0, 0);
      filteredData = filteredData.filter(({ treatment_month }) => {
        const date = cohortChartDateFormat(treatment_month);
        return date >= fromDateSelected;
      });
    }

    if (toDate) {
      let toDateSelected = new Date(toDate).setHours(0, 0, 0, 0);
      filteredData = filteredData.filter(({ treatment_month }) => {
        const date = cohortChartDateFormat(treatment_month);
        return date <= toDateSelected;
      });
    }

    setAggregatedData(mergeData(deepClone(filteredData)))

    setCohortData(filteredData);
    setHeaderArrayData(numberArray);
  };

  const setMinYear = (data) =>{
    let minValue = data[0]?.treatment_month;
    let minDate = `${minValue?.slice(-4)}-01-01`;
    setMinDate(minDate);
  }

  const mergeData = (data) => {
    // Step 1: Merge clientCount and concatenate data arrays
    const merged = data.reduce((acc, curr) => {
        acc.clientCount += curr.clientCount;
        acc.data = acc.data.concat(curr.data);
        return acc;
    }, { clientCount: 0, data: [] });

    // Step 2: Combine entries with the same month_number
    const aggregatedData = merged.data.reduce((acc, curr) => {
        const found = acc.find(item => item.month_number === curr.month_number);
        if (found) {
            found.met += Number(curr.met);
            found.total_objectives += Number(curr.total_objectives);
            found.total_percentage = Math.trunc(Number(((found.met / found.total_objectives) * 100) || 0));
        } else {
            acc.push({ ...curr });
        }
        return acc;
    }, []);

    merged.data = aggregatedData;
    return merged;
}

  const getCohortAnalysisChartData = async () => {
    try {
      setLoader(true);
      const {data: { data }} = await genesisServices.getCohortAnalysisData();
      console.log("======>data", data);
      const { all_location : {learnerall: { All : data3 } } } = data;
      const { finalData, numberArray } = chartDataConverter([...data3]);
      setCohortData(finalData);
      setAggregatedData(mergeData(deepClone(finalData)))
      setHeaderArrayData(numberArray);
      setCompleteData(data);
      setMinYear(finalData)
      setLocationList(data?.locations)
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCohortAnalysisChartData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(completeData).length !== 0) {
      applyFilter();
    }
  }, [treatmentType, learnerType, fromDate, toDate, location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loader ? (
        <LoaderWithTimer />
      ) : error ? (
        <ErrorPage errorMessage={errorMessage} />
      ) : (
        <div className="main-container">
          <div className="chart-container">
            <div className={classes.titleContainer}>
              <h4 className={classes.title}>
                Client Progress Over Treatment Period
              </h4>
            </div>
            <Filter
              treatmentType={treatmentType}
              handleSetTreatmentType={handleSetTreatmentType}
              learnerFilter={true}
              handleLearnerTypeChange={handleLearnerTypeChange}
              learnerType={learnerType}
              dateDatePickerFilter={true}
              onToDateChange={onToDateChange}
              toDate={toDate}
              onFromDateChange={onFromDateChange}
              fromDate={fromDate}
              minDate={minDate}
              locationFilter={true}
              locationList = {locationList}
              location={location}
              handleLocationChange={handleLocationChange}
            />
            {cohortData?.length === 0 ? (
              <div className="empty-container">
                <h6 className="empty-container-text">No Data Available.</h6>
              </div>
            ) : (
              <>
                <p className={classes.verticalLegend}>Treatment Start Month</p>
                <div className={classes.mainLegendContainer}>
                  <div className={classes.legendInnerContainer}>
                    <p className={classes.horizontalLegend}>Treatment Month Number</p>
                    <div className={classes.legendContainer}>
                      <span>Total Objectives:</span>
                      <div className={classes.lableContainer}>
                        <span>0%-25%</span>
                        <span>26%-50%</span>
                        <span>51%-75%</span>
                        <span>76%-100%</span>
                      </div>
                    </div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {options.map(({value, key}) => (
                        <MenuItem key={key} onClick={() =>handleOnMenuChange(key)}>
                          {value}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
                <table className="cohort-analysis-table" id="cohort-table">
                  <thead>
                    <tr>
                      <th>Starting Month</th>
                      <th>Client Count</th>
                      {headerArrayData.map((dataMonthNumber, index) => (
                        <th key={index}>{dataMonthNumber}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {cohortData?.map(
                      ({ treatment_month, clientCount, data},index,arr) => {
                        return (
                          <tr key={index}>
                            <td onClick={() => handleClientDetailsModal(true,treatment_month, clientCount)}>
                              {treatment_month.replace(/([a-zA-Z]+)(\d+)/,"$1 $2")}
                            </td>
                            <td>{clientCount}</td>
                            {data.map((item2, index) => {
                              let value = item2?.total_percentage;
                              if (value === "" && value !== 0) {
                                return (
                                  <td
                                    className="empty-data-cell"
                                    key={index}
                                  ></td>
                                );
                              } else {
                                return (
                                  <CustomStyledTooltip
                                    title={<CustomTooltip data={item2} />}
                                    placement="top-start"
                                    key={index}
                                  >
                                    <td
                                      style={{
                                        backgroundColor: generateColor2(value),
                                      }}
                                    >
                                      {`${value} %`}
                                    </td>
                                  </CustomStyledTooltip>
                                );
                              }
                            })}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                  <thead>
                    <tr>
                      <th>Total</th>
                      <th>{aggregatedData?.clientCount}</th>
                      {aggregatedData?.data?.map((item, index) =>{
                        let value = Math.trunc(item?.total_percentage);
                        return(
                          <CustomStyledTooltip
                            title={<CustomTooltip data={item} />}
                            placement="top-start"
                            key={index}
                          >
                            <td
                              style={{
                                backgroundColor: generateColor2(value),
                              }}
                            >
                              {`${value} %`}
                            </td>
                          </CustomStyledTooltip>
                        )
                      })}
                    </tr>
                  </thead>
                </table>
              </>
            )}
          </div>
        </div>
      )}
      { showClientDetailsModal && <ClientDetailsModal 
                                      open={showClientDetailsModal} 
                                      handleClose={handleClientDetailsModal}
                                      treatmentMonth={treatmentMonth}
                                      location={location}
                                      treatmentType={treatmentType}
                                      learnerType={learnerType}
                                    />}
    </div>
  );
};

export default CohortAnalysisChart;
