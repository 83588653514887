import { useContext, useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { WarningAmber } from '@mui/icons-material';
import upArrow from "../../Assets/Images/double-up-arrow.svg";
import downArrow from "../../Assets/Images/double-down-arrow.svg";

import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

const PatientSummaryCard = () => {
  const { state } = useContext(WcchcPatientSummaryContext);
  const { patientSummary: statePatientSummary } = state;
  const [expand, setExpand] = useState(false);
  const [summary, setSummary] = useState("");
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const text = statePatientSummary;
    setShowMore(text?.length > 400);
    if (!expand) {
      setSummary(text?.length > 400 ? `${text.slice(0, 440)}...` : text);
    } else {
      setSummary(text);
    }
  }, [expand, statePatientSummary]);

  // if (!propPatientSummary && (!statePatientSummary || statePatientSummary.length === 0)) {
  //   console.log("Inside null condition")
  //   return null;
  // }

  return (
    <>
      <Box className="summary-card">
        <Box className="summary-card-header">
          <Typography className="header-text">Summary</Typography>
        </Box>
        <Box className="summary-card-body">
          {!state.isFetchingSummary ? (
            <>
            <Typography component="span" className="summary-text">
              {summary}
            </Typography>
            </>
          ) : (
            <>
              <Skeleton height={25} />
              <Skeleton height={25} />
              <Skeleton height={25} width={200} />
            </>
          )}
          <Box
            className="expandable-box"
            component="span"
            onClick={() => setExpand(!expand)}
          >
            {!state.isFetchingSummary && showMore && (
              <>
                <Typography component="span" className="expand-button">
                  Read {expand ? "Less..." : "More..."}
                </Typography>

                {expand ? (
                  <img className="expand-icon" src={upArrow} alt="up-arrow" />
                ) : (
                  <img
                    className="expand-icon"
                    src={downArrow}
                    alt="down-arrow"
                  />
                )}
              </>
            )}
          </Box>
          <Box className="caution-container">
              <WarningAmber style={{fill: "#FD0808"}}/>
              <Typography className="disclaimer-text">
                  Caution - This summary is generated by ChatGPT and should not replace the clinician's independent review of patient medical record. 
              </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PatientSummaryCard;
