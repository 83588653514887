import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  Alert
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowRightSharpIcon from "@mui/icons-material/KeyboardDoubleArrowRightSharp";
import ExpandIcon from "../../../Assets/Images/expand-icon.png";
import services from "../../../Services/patientServices";
import PatientsComparisonModal from "./Modals/PatientsComparisonModal";
import { PatientSetContext } from "../../../Contexts/patientSetContext";
import { PyramidContext } from "../../../Contexts/uiContext";
import { toPluralize } from "../../../Utils/stringOprations";
import { PatientComparisionSkeleton } from "../../../Components/SkeletonLoader/DashboardSkeleton";
import CanvasPieAndStackChart from "./CanvasPieAndStackChart";

const IMPACTABILITY_INFO = [
  {
    key: "critical",
    title: "Critical",
    color: "#FFCECE",
    toolTipColor: "#D68888",
    filterKey: "imp_risk_crit_risk",
  },
  {
    key: "high",
    title: "High",
    color: "#DFFFD9",
    toolTipColor: "#A3D499",
    filterKey: "imp_risk_high_risk",
  },
  {
    key: "moderate",
    title: "Moderate",
    color: "#C3FAFF",
    toolTipColor: "#8AD8DF",
    filterKey: "imp_risk_mod_risk",
  },
  {
    key: "low",
    title: "Low",
    color: "#FFF0DF",
    toolTipColor: "#D6AF83",
    filterKey: "imp_risk_low_risk",
  },
];

const COMPREHENSIVE_INFO = [
  {
    key: "critical",
    title: "Critical",
    color: "#FFCECE",
    toolTipColor: "#C07D7D",
    filterKey: "comp_risk_crit_risk",
  },
  {
    key: "high",
    title: "High",
    color: "#DFFFD9",
    toolTipColor: "#94C68B",
    filterKey: "comp_risk_high_risk",
  },
  {
    key: "moderate",
    title: "Moderate",
    color: "#C3FAFF",
    toolTipColor: "#66BBC2",
    filterKey: "comp_risk_mod_risk",
  },
  {
    key: "low",
    title: "Low",
    color: "#FFF0DF",
    toolTipColor: "#D3B38E",
    filterKey: "comp_risk_low_risk",
  },
];

export default function PatientsComparisonChart({ cohortId, selectedDate }) {
  const history = useNavigate();
  const { patientSetInfo } = useContext(PatientSetContext);
  const { info, setInfo } = useContext(PyramidContext);

  const [comprehensiveData, setComprehensiveData] = useState([]);
  const [impactabilityData, setImpactabilityData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const cohortRiskImpact = async (cohortId, selectedDate) => {
    try {
      setIsFetching(true);
      const result = await services.riskImpact({
        cohort_id: cohortId,
        selected_date: selectedDate,
      });

      const { impactability, comprehensive } = result.data.result;

      let isNoDataAvailable = impactability?.length === 0 || comprehensive?.length === 0 

      if (impactability && comprehensive) {
        const _comprehensiveData = COMPREHENSIVE_INFO.map((ele) => {
          return {
            ...ele,
            ...comprehensive[ele.key],
          };
        });

        const _impactabilityData = IMPACTABILITY_INFO.map((ele) => {
          return {
            ...ele,
            ...impactability[ele.key],
          };
        });

        setImpactabilityData(_impactabilityData);
        setComprehensiveData(_comprehensiveData);
      } else {
        setImpactabilityData([]);
        setComprehensiveData([]);
      }

      setNoDataAvailable(isNoDataAvailable)

    } catch (error) {
      console.error("Cohort Risk Impact : ", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handlePatientListRedirect = (compKey, impKey) => {
    const compData = COMPREHENSIVE_INFO.find((doc) => doc.key === compKey);
    const impData = IMPACTABILITY_INFO.find((doc) => doc.key === impKey);
    const _riskFilter = [
      {
        name: compData.filterKey,
        selected: 1,
        mnemo: "comp_risk",
      },
      {
        name: impData.filterKey,
        selected: 1,
        mnemo: "imp_risk",
      },
    ];

    setInfo({
      ...info,
      currentui: "patientview",
      prevui: "summary",
      riskFilter: _riskFilter,
    });

    history({
      pathname: "/patient-list",
      state: {
        uititle: `All Active ${toPluralize(patientSetInfo.patient_name)}`,
      },
    });
  };

  useEffect(() => {
    if (cohortId && selectedDate) {
      cohortRiskImpact(cohortId, selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId]);

  return (
    <React.Fragment>
      <Card
        sx={{
          height: "480px",
          position: "relative",
          borderRadius: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent:  noDataAvailable ? "" : "space-between",
        }}
      >
        {isFetching ? (
          <PatientComparisionSkeleton />
        ) : (
          <React.Fragment>
            <CardHeader
              title="Risk and Impactability Assessment"
              titleTypographyProps={{ fontWeight: 700, fontSize: 20 }}
            />
            {
              noDataAvailable ?
              <Alert
                icon={false}
                severity="warning"
                className="my-4 mx-2 text-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                No data available.
              </Alert>
              :
              <>
              <CardContent sx={{ pt: 0 }}>
                  <Grid container justifyContent={"space-between"} pb={3}>
                    <Grid item>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontWeight: 700, fontSize: 16 }}
                      >
                        Comprehensive Risk/Impactability Stratification
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        <img src={ExpandIcon} alt="" style={{ right: 0 }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                    <Chart
                      impactabilityData={impactabilityData}
                      comprehensiveData={comprehensiveData}
                      handlePatientListRedirect={handlePatientListRedirect}
                    />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions
                  sx={{ justifyContent: "space-between", p: "12px 20px" }}
                >
                  <Typography
                    fontStyle={"italic"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <InfoOutlinedIcon sx={{ width: 16, height: 16 }} />
                    Click on each chart category to see patient details.
                  </Typography>
                  <Link
                    onClick={() => history("/patient-list")}
                    sx={{ display: "flex", cursor: "pointer" }}
                  >
                    View Details
                    <KeyboardDoubleArrowRightSharpIcon />
                  </Link>
                </CardActions>
              </>
            }
          </React.Fragment>
        )}
      </Card>
      <PatientsComparisonModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        impactabilityData={impactabilityData}
        comprehensiveData={comprehensiveData}
        handlePatientListRedirect={handlePatientListRedirect}
      />
    </React.Fragment>
  );
}

export const Chart = ({
  comprehensiveData,
  impactabilityData,
  handlePatientListRedirect,
}) => {
  return (
    <>
      <CanvasPieAndStackChart comprehensiveData={comprehensiveData} impactabilityData={impactabilityData} handlePatientListRedirect={handlePatientListRedirect} />
    </>
  );
};
