import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Accrodion imports
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";

import PatientRecommendations from "../PatientRecommendations/PatientRecommendations";
import PatientDemographics from "../PatientDemographics/PatientDemographics";
import PatientNotes from "../PatientNotes/PatientNotes";

import IconButton from "@mui/material/IconButton";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { usePatientContext } from "../../Contexts/patientContext";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";

import "./PatientInfo.scss"; // Importing CSS file

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: `none`,
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.6rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  height: "40px",
  minHeight: "40px",
  borderBottom: "1px solid #0082b2",
  color: "#0082b2",
  backgroundColor: "white",
  "& .MuiAccordionSummary-expandIconWrapper": {
    fill: "#0082b2",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiSvgIcon-root": {
    fill: "inherit !important",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "100%",
}));

const PatientInfo = ({ close, selectedSection }) => {
  const { state } = useContext(DropdownFilterContext);
  const [expanded, setExpanded] = useState(selectedSection);
  const {
    patientDetails,
    isFetchingDetails,
    isHalfScreen,
    changeDialogScreen,
    handleBackForthNavigation,
    currentPatientIndex,
  } = usePatientContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    if (patientDetails?.coordinator_list.length <= 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const recommendationComponent = patientDetails ? (
    <PatientRecommendations patientData={patientDetails} />
  ) : (
    "NA"
  );

  const detailsComponent = <PatientDemographics patientData={patientDetails} />;

  const notesComponent = (
    <PatientNotes patientData={patientDetails}></PatientNotes>
  );

  const accordianList = [
    {
      title: "Details",
      component: detailsComponent,
      id: "details",
    },
    {
      title: "Notes",
      component: notesComponent,
      id: "notes",
    },
    {
      title: "Recommendations",
      component: recommendationComponent,
      id: "recommendations",
    },
  ];

  return (
    <>
      {!isFetchingDetails ? (
        <div className="patient-info-container">
          <div className="main-info-wrapper">
            <div className="info-btn-wrapper">
              <Button
                variant="text"
                startIcon={<ArrowBackIcon className="prev-btn" />}
                className="prev-btn"
                onClick={() => handleBackForthNavigation("previous")}
                disabled={currentPatientIndex === 0}
              >
                Previous
              </Button>
              <Button
                variant="text"
                endIcon={<ArrowForwardIcon className="next-btn" />}
                className="next-btn"
                onClick={() => handleBackForthNavigation("next")}
                disabled={currentPatientIndex === state.filteredData.length - 1}
              >
                Next
              </Button>
              <div className="icon-wrapper">
                {isHalfScreen ? (
                  <FullscreenIcon
                    className="full-screen-icon"
                    onClick={() => changeDialogScreen(false)}
                  />
                ) : (
                  <FullscreenExitIcon
                    className="full-screen-icon"
                    onClick={() => changeDialogScreen(true)}
                  />
                )}
                <IconButton
                  color="inherit"
                  onClick={() => {
                    close(false);
                    changeDialogScreen(true);
                  }}
                  aria-label="close"
                  className="close-icon"
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </div>
            </div>
            <div className="patient-info">
              <div className="patient-name">
                {patientDetails?.first_name} {patientDetails?.middle_name}{" "}
                {patientDetails?.last_name}
              </div>
              <div className="patient-age">
                {" "}
                <span className="gender-icon">
                  {patientDetails?.sex === "male" ? (
                    <MaleIcon />
                  ) : (
                    <FemaleIcon />
                  )}
                </span>
                <span>
                  {patientDetails?.sex} {patientDetails?.age}
                </span>
              </div>
              <div className="patient-org-id">
                ID {patientDetails?.org_patient_id}
              </div>
              <div className="patient-coodinators-tooltip">
                <div className="coordinator-list">
                  <strong>Coordinators</strong>:
                  <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {patientDetails?.coordinator_list.length > 1
                      ? "multiple"
                      : patientDetails?.coordinator_list[0]?.name}
                  </Typography>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    PaperProps={{
                      style: {
                        boxShadow: "none",
                        borderRadius: 0,
                      }
                    }}
                  >
                    <ul className="ul-popover">
                      {patientDetails?.coordinator_list.map(
                        (coordinator, index) => {
                          return <li key={index}>{coordinator.name}</li>;
                        }
                      )}
                    </ul>
                  </Popover>
                </div>
              </div>
            </div>

            {/* Accordian */}
            <div className="accordion wrapper">
              <div>
                {accordianList.map((item, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.component}</AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
            {/* Accordian */}
          </div>
        </div>
      ) : (
        <div className="loading-wrapper">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PatientInfo;
