import React, { useState, useEffect } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import formatHeading from "../../../Utils/formatHeading";
import Spinner from "../../../Components/Spinner/Spinner";
import InsideReportFilter from "./InsideReportFilter"
import { deepClone } from "../../../Utils/filterPatientList";

const ReportInsideTable = (props) => {
  const { heading, headers, isFetching, reportTitle} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState(props?.list);
  const [originalList, setOriginalList] = useState(props?.list);
  const [currentClickedHeading, setCurrentClickedHeading] = useState("");
  const [searchValue, setSearchValue] = useState("")
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("")


  const handleClick = (event,heading) => {
    if(currentClickedHeading !== heading){
      setSearchValue("");
    }
    setAnchorEl(event.currentTarget);
    setCurrentClickedHeading(heading);
    setIsFilterApplied(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetForm = () =>{
    if(currentClickedHeading === "date"){
      setFromDate(null)
      setToDate(null)
    }else{
      setSearchValue("")
    }
  }

  const onFromDateChange = (value) =>{
     setFromDate(new Date(value));
  }

  const onToDateChange = (value) =>{
    setToDate(new Date(value));
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(()=>{
    setList(props?.list)
    setOriginalList(props?.list)
  },[props?.list])

  const applyFilter = () =>{
    let clonedList = deepClone(originalList);
    let updatedList;
    if(currentClickedHeading === "date"){
        if(fromDate === null && toDate === null){
          setList(clonedList);
          return
        }
        let startDate = fromDate;
        let endDate = toDate;
        updatedList = clonedList.filter(({date}) => {
            let dateStr = date.split(',')[0] + ',' + date.split(',')[1];; 
            let itemDate = new Date(dateStr);
            return itemDate >= startDate && itemDate <= endDate;  
        }
      );
    }else if(currentClickedHeading === "service_type"){
      updatedList = clonedList.filter(item => 
        item[currentClickedHeading]?.includes(searchValue)
      );
    } else{
      updatedList = clonedList.filter(item => 
        item[currentClickedHeading]?.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    setAnchorEl(null);
    setList(updatedList)
  }

  return (
    <div className="ReportInsideTable" style={{ minHeight: "100px" }}>
      {isFetching ? (
        <Spinner />
      ) : !isFetching && !list?.length && !isFilterApplied? (
        <div
          className="mt-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
            <strong>No Data Available!</strong>
        </div>
      ) : (
        <div className="tableScroll">
          <div className="inside__table__heading">{heading}</div>
          <table className="table">
            <thead>
              <tr className="tRow" aria-describedby="insidetablefilter">
                {headers?.map((heading, index) => (
                      <th key={index} className="inside_table_heading_th">
                        {formatHeading(heading)} 
                        {(reportTitle === "Client Report" && heading !== "length_(hours)") && <FilterAltIcon onClick={(e)=>handleClick(e, heading)}/>}
                      </th>
                ))}
              </tr>
              <InsideReportFilter 
                  anchorEl={anchorEl} 
                  open={open} 
                  id={id} 
                  handleClose={handleClose}
                  heading={currentClickedHeading}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  applyFilter={applyFilter}
                  resetForm={resetForm}
                  onFromDateChange={onFromDateChange}
                  onToDateChange={onToDateChange}
                  fromDate={fromDate}
                  toDate={toDate}
              />
            </thead>
            <tbody>
              {list?.map((listItem, index) => (
                <tr key={index} className="entryHasNotes tRow">
                  {headers?.map((element, index) => (
                    <td
                      key={index}
                      className={
                        element === "risk_level" ? listItem[element] : ""
                      }
                    >
                      {" "}
                      {listItem[element]}{" "}
                    </td>
                  ))}
                </tr>
              ))}
              {
                (!list.length && isFilterApplied) && <tr><td colSpan={5} style={{textAlign: "center"}}><strong>No Data Available!</strong></td></tr>
              }
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReportInsideTable;
