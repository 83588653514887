import React, { useContext, useEffect, useState } from "react";
import { CssBaseline, Box, Toolbar, Container, Grid } from "@mui/material";
//Components
import AppBar from "./Components/AppBar";
import CohortTabs from "./Components/CohortTabs";
import PatientsComparisonChart from "./Components/PatientsComparisonChart";
import InsightsAndRecommendation from "./Components/InsightsAndRecommendation";
import CohortComparisonTable from "./Components/CohortComparisonTable";
import "./_Dashboard.scss";
import { PatientSetContext } from "../../Contexts/patientSetContext";
import { PatientComparisionSkeleton } from "../../Components/SkeletonLoader/DashboardSkeleton";
import { MetaDetailsContext } from "../../Contexts/metaDetailsContext";

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(65);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(11);
  const { fetchAllPatientSets, fetchingPatientSet, patientSetInfo } =
    useContext(PatientSetContext);
  const { setPageTitle } = useContext(MetaDetailsContext);

  useEffect(() => {
    fetchAllPatientSets();
    setPageTitle("FHS: Summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={open}
        setOpen={setOpen}
        drawerWidth={drawerWidth}
        setDrawerWidth={setDrawerWidth}
      />
      <Box
        component="main"
        className="dashboard-style"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          marginLeft: open ? 0 : `-${drawerWidth}px`,
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ p: "30px 50px" }}>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            justifyContent={"space-between"}
          >
            <CohortTabs
              selectedTimeFrame={selectedTimeFrame}
              setSelectedTimeFrame={(timeFrame) => setSelectedTimeFrame(timeFrame)} />
            <Grid item xs={12} md={6}>
              {!fetchingPatientSet && patientSetInfo?.patient_set_id ? (
                <PatientsComparisonChart
                  cohortId={patientSetInfo?.patient_set_id}
                  selectedDate={patientSetInfo?.selected_date}
                />
              ) : (
                <PatientComparisionSkeleton />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InsightsAndRecommendation />
            </Grid>
            <CohortComparisonTable
              selectedTimeFrame={selectedTimeFrame}
            />
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
