import apiClient from "./apiClient";

const getLabResults = async (pid, org_id) => {
  return await apiClient("wcchc").get(`labresults/${pid}?org_id=${org_id}`);
};

const getAlertNotifications = async (pid, org_id) => {
  return await apiClient("wcchc").get(`alertNotification/${pid}?org_id=${org_id}`);
};

const getAllSmartAlerts = async (pid, org_id) => {
  return await apiClient("wcchc").get(`listSmartAlerts/${pid}?org_id=${org_id}`);
};

const markAsViewedNotifications = async (pid, org_id, body) => {
  return await apiClient("wcchc").put(`alertNotification/${pid}/markAsViewed?org_id=${org_id}`, body);
}

const medications = async (pid, org_id) => {
  return await apiClient("wcchc").get(`medications/${pid}?org_id=${org_id}`);
};

const vitals = async (pid, org_id) => {
  return await apiClient("wcchc").get(
    `vitalinformation/${pid}?org_id=${org_id}`
  );
};

const problemsIdentified = async (pid, org_id) => {
  return await apiClient("wcchc").get(
    `problemidentified/${pid}?org_id=${org_id}`
  );
};

const comprehensiveRisk = async (pid, org_id) => {
  return await apiClient("wcchc").get(
    `comprehensiverisk/${pid}?org_id=${org_id}`
  );
};

const patientDetails = async (pid, org_id) => {
  return await apiClient("wcchc").get(`patientdetails/${pid}?org_id=${org_id}`);
};

const patientVisits = async (pid, org_id) => {
  return await apiClient("wcchc").get(`patientvisits/${pid}?org_id=${org_id}`);
};

const sdohAnalysis = async (pid, org_id) => {
  return await apiClient("wcchc").get(`sdohanalysis/${pid}?org_id=${org_id}`);
};

const smartAlerts = async (pid, org_id) => {
  return await apiClient("wcchc").get(`smartalerts/${pid}?org_id=${org_id}`);
};

const validateAlert = async (body) => {
  return await apiClient("wcchc").post(`validatealert`, body);
}

const patientSummary = async (pid, org_id) => {
  return await apiClient("wcchc").get(`patientsummary/${pid}?org_id=${org_id}`);
}

const referrals = async (pid, org_id) => {
  return await apiClient("wcchc").get(`referrals/${pid}?org_id=${org_id}`);
};

const getWidgetUpdatedDate = async (widget_name) => {
  return await apiClient("wcchc").get(`widget-update-date/${widget_name}`);
};

const combinedServices = {
  getLabResults,
  getAlertNotifications,
  getAllSmartAlerts,
  markAsViewedNotifications,
  medications,
  vitals,
  problemsIdentified,
  comprehensiveRisk,
  patientDetails,
  patientVisits,
  sdohAnalysis,
  smartAlerts,
  validateAlert,
  patientSummary,
  referrals,
  getWidgetUpdatedDate,
};

export default combinedServices;
