import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ThumbsDownIcon, ThumbsUpIcon } from "./Icons";


const useStyles = makeStyles((theme) => ({
  toggleButton: {
    border: "0px !important",
    margin: "5px",
    borderRadius: "5px !important",
  },
  upTrue: {
    backgroundColor: "rgb(104, 235, 141) !important",
  },
  upFalse: {
    backgroundColor: "rgba(104, 235, 141, 0.3) !important",
  },
  downTrue: {
    backgroundColor: "rgb(235, 104, 104) !important",
  },
  downFalse: {
    backgroundColor: "rgba(235, 104, 104, 0.3) !important",
  },
}));

const SmartAlertSwitch = ({
  index,
  id,
  value,
  data,
  setData,
  validIds,
  setValidIds,
  invalidIds,
  setInvalidIds,
}) => {
  const classes = useStyles();

  const [check, setChecked] = useState(false);

  const handleChange = (event, newValue) => {
    setChecked(newValue);
    const newData = data;
    newValue
      ? setValidIds([...validIds, index])
      : setValidIds(validIds.filter((i) => i !== index));
    !newValue
      ? setInvalidIds([...invalidIds, index])
      : setInvalidIds(invalidIds.filter((i) => i !== index));
    const icd = newData.icd.find((i) => i.id === index);
    icd.applicable = newValue;
    newValue
      ? (icd.addressed_date = dayjs().format("MM/DD/YYYY"))
      : (icd.addressed_date = "-");
    setData(JSON.parse(JSON.stringify(newData)));
  };

  useEffect(() => {
    setChecked(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, value]);

  return (
    <Box>
      <ToggleButtonGroup exclusive value={check} onChange={handleChange}>
        <ToggleButton
          className={`${classes.toggleButton} ${
            check ? classes.upTrue : classes.upFalse
          }`}
          value={true}
          disableRipple
        >
          <ThumbsUpIcon />
        </ToggleButton>
        <ToggleButton
          className={`${classes.toggleButton} ${
            check ? classes.downFalse : classes.downTrue
          }`}
          value={false}
          disableRipple
        >
          <ThumbsDownIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default SmartAlertSwitch;
