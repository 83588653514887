import React from 'react';
import { Box, Grid, Skeleton, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";
import { ThumbsDownIcon, ThumbsUpIcon } from "./Icons";

const RECOMMENDED_DATA = [
  {
    "id": 1,
    "title": "Transportation Assistance",
    "date": "12/16/2023",
    "subItems": [
      {
        "id": 1,
        "title": "Volunteer Driver Programs",
        "details": [
          { "name": "Anticipated Savings", "value": 100 }, 
          { "name": "Service Count", "value": 0 },
          { "name": "Last Provided Date", "value": "-"},
        ]
      },
      {
        "id": 2,
        "title": "Public Transportation Assistance",
        "details": [
          { "name": "Anticipated Savings", "value": 90 }, 
          { "name": "Service Count", "value": 0 },
          { "name": "Last Provided Date", "value": "-" }
        ]
      },
      {
        "id": 3,
        "title": "Dial-a-Ride Services",
        "details": [
          { "name": "Anticipated Savings", "value": 120 },  
          { "name": "Service Count", "value": 0 },
          { "name": "Last Provided Date", "value": "-" }
        ]
      },
      {
        "id": 4,
        "title": "Ambulance Transport",
        "details": [
          { "name": "Anticipated Savings", "value": 50 },
          { "name": "Service Count", "value": 0 },
          { "name": "Last Provided Date", "value": "-" }
        ]
      },
      {
        "id": 5,
        "title": "Non-Emergency Medical Transportation (NEMT)",
        "details": [
          { "name": "Anticipated Savings", "value": 105 },  
          { "name": "Service Count", "value": 4},  // Highlighted service with count
          { "name": "Last Provided Date", "value": "01/02/2024" }
        ]
      }
    ]
  },  
  {
    "id": 2,
    "title": "Food Assistance",
    "date": "12/16/2023",
    "subItems": [
      {
        "id": 1,
        "title": "Food Pantry Assistance",
        "details": [
          { "name": "Anticipated Savings", "value": 125 }, 
          { "name": "Service Count", "value": 10 },  
          { "name": "Last Provided Date", "value": "12/10/2023" }
        ]
      },
      {
        "id": 2,
        "title": "Supplemental Nutrition Assistance Program (SNAP)",
        "details": [
          { "name": "Anticipated Savings", "value": 200 },  
          { "name": "Service Count", "value": 1 }, 
          { "name": "Last Provided Date", "value": "11/01/2023" }
        ]
      },
      {
        "id": 3,
        "title": "Meals on Wheels",
        "details": [
          { "name": "Anticipated Savings", "value": 75 }, 
          { "name": "Service Count", "value": 0 },  
          { "name": "Last Provided Date", "value": "-" }
        ]
      },
      {
        "id": 4,
        "title": "Other Food Assistance Programs",
        "details": [
          { "name": "Anticipated Savings", "value": 0 },  
          { "name": "Service Count", "value": 0 },  
          { "name": "Last Provided Date", "value": "-" }
        ]
      }
    ]
  },
  {
    "id": 3,
    "title": "Housing Assistance",
    "date": "12/16/2023",
    "subItems": [
      {
        "id": 1,
        "title": "Emergency Shelter",
        "details": [
          { "name": "Anticipated Savings", "value": 500 },  
          { "name": "Service Count", "value": 2 },  
          { "name": "Last Provided Date", "value": "10/25/2023" }
        ]
      },
      {
        "id": 2,
        "title": "Rental Assistance Programs",
        "details": [
          { "name": "Anticipated Savings", "value": 350 },  
          { "name": "Service Count", "value": 1 },  
          { "name": "Last Provided Date", "value": "12/01/2023" }
        ]
      },
      {
        "id": 3,
        "title": "Housing Repair or Modification Assistance",
        "details": [
          { "name": "Anticipated Savings", "value": 200 },  
          { "name": "Service Count", "value": 1 }, 
          { "name": "Last Provided Date", "value": "09/15/2023" }
        ]
      },
      {
        "id": 4,
        "title": "Other Housing Assistance Programs",
        "details": [
          { "name": "Anticipated Savings", "value": 310 },  
          { "name": "Service Count", "value": 0 },  
          { "name": "Last Provided Date", "value": "-" }
        ]
  }
]
  }
]

const useStyles = makeStyles((theme) => ({
    mainBox: {
      maxWidth: "450px",
      margin: "5px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
    },
    listContainer: {
      backgroundColor: "#E7E7E7",
      width: "100%",
      maxWidth: "440px",
      borderRadius: "5px",
      padding: "5px 10px !important",
    },
    selectedListContainer: {
      backgroundColor: "#E3F5FF !important",
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "20px solid transparent",
      transform: "rotate(90deg)",
    },
    selectedTriangle: {
      borderBottom: "20px solid #E3F5FF !important",
    },
    detailsContainer: {},
    descriptionText: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight: "21px",
    },
    sourceContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sourceFont: {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "20px",
    },
    sourceDate: {
      color: "#797979",
    },
    sourceName: {
      color: "#0066CB",
    },
    recommendedContainer:{
      marginTop: "10px",
      marginRight: "10px"
    },
    recommendedDetailsTitle:{
        padding: '7px 10px'
    },
    recommendedDetailsInnerContainer1:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: "#E7E7E7",
    },
    recommendedDetailsInnerContainer2:{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 10px !important'
    },
    toggleButton: {
      border: "0px !important",
      margin: "5px",
      borderRadius: "5px !important",
    },
    upTrue: {
      backgroundColor: "rgb(104, 235, 141) !important",
    },
    upFalse: {
      backgroundColor: "rgba(104, 235, 141, 0.3) !important",
    },
    downTrue: {
      backgroundColor: "rgb(235, 104, 104) !important",
    },
    downFalse: {
      backgroundColor: "rgba(235, 104, 104, 0.3) !important",
    },
    gridContainer:{
      height: "260px",
      overflow: "auto",
      marginTop: "0"
    },
    skeletonContainer:{
      padding: "0 2% !important"
    }
  }));

  const RecommendedList = ({
    id,
    title,
    date,
    index,
    selectedIndex,
    handleClick,
  }) => {
    const classes = useStyles();
  
    return (
      <Box className={classes.mainBox} onClick={() => handleClick(index, id)}>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedListContainer : ""
          } ${classes.listContainer}`}
        >
          <Box className={classes.detailsContainer}>
            <Typography className={classes.descriptionText}>
              {title}
            </Typography>
          </Box>
          <Box className={classes.sourceContainer}>
            <Typography className={`${classes.sourceFont} ${classes.sourceDate}`}>
              {date}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`${
            index === selectedIndex ? classes.selectedTriangle : ""
          } ${classes.triangle}`}
        ></Box>
      </Box>
    );
  };

const RecommendedDetails = ({index,title,list}) =>{
  const [check, setChecked] = useState(false);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setChecked(newValue);
  }

   return(
    <Box className={classes.recommendedContainer}>
      {/* <h1>Recommeded Data</h1> */}
      <Box className={classes.recommendedDetailsInnerContainer1}>
        <Typography className={classes.recommendedDetailsTitle}>{ index + 1}.  {title}</Typography>
        <Box>
          <ToggleButtonGroup exclusive value={check} onChange={handleChange}>
            <ToggleButton
              className={`${classes.toggleButton} ${
                check ? classes.upTrue : classes.upFalse
              }`}
              value={true}
              disableRipple
            >
              <ThumbsUpIcon />
            </ToggleButton>
            <ToggleButton
              className={`${classes.toggleButton} ${
                check ? classes.downFalse : classes.downTrue
              }`}
              value={false}
              disableRipple
            >
              <ThumbsDownIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box className={classes.recommendedDetailsInnerContainer2}>
        {list?.map(({name, value}, index) =>(
          <Typography key={index}> {name} : {value}</Typography>
        ))}
      </Box>
    </Box>
   )
}
  

const RecommendServices = () => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedItem, setSelectedItem] = useState(RECOMMENDED_DATA[0])
    const { state } = useContext(WcchcPatientSummaryContext);

    const handleClick = (index, id) => {
        console.log("index=====>",index);
        setSelectedIndex(index);
        setSelectedItem(RECOMMENDED_DATA[index])
    };

    return(
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.gridContainer}
      >
        {
          state.isFetchingSmartAlerts ? (
            <Grid item xs={12}>
              {new Array(5).fill(0).map((_, index) => (
                <Box className={classes.skeletonContainer}>
                  <Skeleton key={index} height={50} sx={{width: "100%"}}/>
                </Box>
              ))}
            </Grid>
          )
          :
          state.smartAlerts.length === 0 ?(
            <>
              <Grid item xs={4}>
                <Box className={classes.mainBox}>
                  <Typography className={classes.descriptionText}>
                    No Recommended Services
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}></Grid>
            </>
          )
          :
          <>
          <Grid item xs={4}>
            {RECOMMENDED_DATA.map((Recommended, index) => {
                  return (
                    <RecommendedList
                      key={index}
                      index={index}
                      selectedIndex={selectedIndex}
                      handleClick={handleClick}
                      title={Recommended.title}
                      date={Recommended.date}
                    />
                  );
                })}
          </Grid>
          <Grid item xs={8}>
            {selectedItem?.subItems?.map((item,index) =>(
                <RecommendedDetails 
                  key={index}
                  title={item.title}
                  list={item.details}
                  index={index}
                />
            ))}
          </Grid>
          </>
        }
      </Grid>
    )
};

export default RecommendServices