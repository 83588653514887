import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReportInsideTable from "./ReportInsideTable";
import TrendUpIcon from "../../../Assets/Images/trend-up.png";
import TrendDownIcon from "../../../Assets/Images/trend-down.png";
import TrendEqualIcon from "../../../Assets/Images/trend-equal.png";
import TrendNotAvailableIcon from "../../../Assets/Images/trend-not-available.png";
import { Tooltip } from "@mui/material";
import popupIcon from "../../../Assets/Images/popupIcon.png";
import BracketedText from "../../../Components/BracketedText/BracketedText";
import ListModal from "../../../Components/Modal/ListModal";
import reportServices from "../../../Services/reportServices";

const ReportOuterTableRow = (props) => {
  const { row, columnList } = props;
  const [open, setOpen] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [heading, setHeading] = useState(
    "Provider load, listed are short-term, long-term, and combined care plan counts"
  );
  const icon = {
    equal: TrendEqualIcon,
    up: TrendUpIcon,
    down: TrendDownIcon,
    unchanged: TrendNotAvailableIcon,
  };
  const tooltipData = ["Insurance Agency", "School/Church etc.", "Private Payee"];
  const [headers, setHeaders] = useState([]);
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (selectedColumn && Object.keys(selectedColumn).length !== 0) {
      setHeading(selectedColumn.heading);
      getTableData();
    }
    // eslint-disable-next-line
  }, [selectedColumn]);

  const onTableOpen = (event, column) => {
    setSelectedColumn(column);
    setOpen(!open);
  };

  const handleClick = (column) => {
    if (column.haveInsideTable) {
      setSelectedColumn(column);
    }
    if (column.havePopup) {
      setOpenPopup(true);
      setSelectedColumn(column);
    }
  };

  const getTableData = async () => {
    setIsFetching(true);
    let paramsKeys = selectedColumn.api_params || [];
    const apiUrl = selectedColumn.api_url || "";
    const runtime = selectedColumn?.api_runtime || ""


    if (paramsKeys.length === 0) return;
    // build api body
    let requestBody = {column: selectedColumn?.title};
    paramsKeys.forEach((element) => {
      requestBody[element] = row[element];
    });

    const responseData = await reportServices.reportPostRequest(
      apiUrl,
      requestBody, 
      runtime 
    );
    const { list } = responseData.data.data;
    if (list.length) {
      const headers = Object.keys(list[0]);
      setHeaders(headers);
    }
    setList(list);
    setIsFetching(false);
  };

  return (
    <React.Fragment>
      <tr className="entryHasNotes tRow">
        {columnList.map((column, index) =>
          column.isAvailable ? (
            <td
              className={`tableData ${
                column.title === "name" ? "sticky_first" : ""
              } ${
                column.title === "payee" ? "last-row-col" : ""
              }`}
              key={index}
              onClick={() => handleClick(column)}
            >
              {/* Inside table icon */}
              {column.haveInsideTable ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={(event) => {
                    onTableOpen(event, column);
                  }}
                >
                  {open && column.title === selectedColumn.title ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              ) : null}
              {/* Inside table icon end*/}

              {/* Trend icon and column text */}
              {column.haveTrendIcon ? (
                <img
                  src={icon[row[column.title]]}
                  alt="trend-up"
                  className="trend-up-icon"
                />
              ) : (
                <span>
                  <BracketedText text={row[column.title]} className={column.havePopup ? "popup-text": ""}/>
                </span>
              )}
              {/* Trend icon and column text section end */}

              {/* Funding source icon with tooltip */}
              {column.title === "funding_source" && (
                <Tooltip
                  placement="top"
                  interactive
                  arrow
                  title={
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Funding Source</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tooltipData.map((item) => {
                          return (
                            <tr>
                              <td className="active">{item}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                >
                  <span>
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        verticalAlign: "top",
                        right: "6px",
                        top: "-8px",
                      }}
                      src={popupIcon}
                      alt="popup icon"
                    />
                  </span>
                </Tooltip>
              )}
              {/* Funding source icon with tooltip section end */}
            </td>
          ) : null
        )}
      </tr>
      <tr>
        <td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ReportInsideTable
                isFetching={isFetching}
                column={selectedColumn}
                heading={heading}
                list={list}
                headers={headers}
                reportTitle={props.reportTitle}
              />
            </Box>
          </Collapse>
        </td>
      </tr>
      <ListModal
        open={openPopup}
        isFetching={isFetching}
        column={selectedColumn}
        setOpen={setOpenPopup}
        heading={heading}
        list={list}
        headers={headers}
      />
    </React.Fragment>
  );
};

export default ReportOuterTableRow;
