import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { makeStyles, styled } from "@mui/styles";
import SmartAlertSwitch from "./SmartAlertSwitch";
import dayjs from "dayjs";
// import dayjs from "dayjs";

// import wcchcServices from "../../Services/wcchcServices";
// import { WcchcPatientSummaryContext } from "../../Contexts/wcchcPatientSummaryContext";

// const ToolTipComponent = () =>{
//   return(
//     <Typography 
//       sx={{width: "1000px"}}
//       component="div"
//       componentsProps={{
//           tooltip: {
//               sx: {
//                   width: '500px',
//                   bgcolor: "Red"
//               },
//           },
//       }} 
//     >
//       10/10/2023 Can no longer afford her rent. She has been evicted from her home and is currently residing in her car due to the lack of available shelter options. Left Homelessness. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.  
//     </Typography>
//   )
// }

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Lato',
    lineHeight: '20px',
    border: '1px solid #dadde9',
  },
}));


const useStyles = makeStyles((theme) => ({
  alertDetailContainer: {
    padding: "5px !important",
    fontFamily: "Lato",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "290px",
  },
  icdCodes: {
    minHeight: "235px",
    overflow: "auto"
  },
  icdRow: {
    width: "100%",
    height: "35px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(231, 231, 231, 0.5)",
    marginTop: "5px",
    padding: "0 10px !important",
    fontFamily: "Lato",
  },
  actionDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginBottom: "5px",
    marginRight: "5px",
  },
  fonthighlight:{
    color: "#0066CB",
    fontWeight: 600
  },
  fonthighlight2:{
    // color: "#0066CB"
    fontWeight: 600

  },
  backHighLight:{
    backgroundColor: "#FFFF00"
  },
  pastClaimContainers:{

  },
  pastClaimInnerContainers:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  pastClaimsHeading:{
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    color: '#0066CB'
  },
  pastClaimsMainHeading:{
    color: "#141414",
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    margin: '5px 0px'
  },
  keyword: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "#F5EA14",
    padding: "0px 2px",
    borderRadius: "2px"
  }
}));

const IcdRow = ({
  icd,
  index,
  id,
  data,
  setData,
  validIds,
  setValidIds,
  invalidIds,
  setInvalidIds,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.icdRow}>
      <Box >
        {`${index + 1}. `}
        {icd.description}
        {` (${icd.code})`}
      </Box>
      <SmartAlertSwitch
        index={icd.id}
        id={id}
        value={icd.applicable}
        data={data}
        setData={setData}
        validIds={validIds}
        setValidIds={setValidIds}
        invalidIds={invalidIds}
        setInvalidIds={setInvalidIds}
      />
    </Box>
  );
};

const SmartAlertDetails = ({ alert, selectedIndex }) => {
  const classes = useStyles();

  // const { state, dispatch } = useContext(WcchcPatientSummaryContext);

  // const [alert, setAlert] = useState({});
  const [data, setData] = useState([]);
  // const [disabled, setDisabled] = useState(false);
  const [validIds, setValidIds] = useState([]);
  const [invalidIds, setInvalidIds] = useState([]);
  // const [savingChanges, setSavingChanges] = useState(false);

  // const handleSaveChanges = async () => {
  //   setSavingChanges(true);
  //   const validProblems = [];
  //   const invalidProblems = [];
  //   [...new Set(validIds)].forEach((id) => {
  //     if (
  //       state.problemsIdentified.results &&
  //       state.problemsIdentified.results.length
  //     ) {
  //       const problem = state.problemsIdentified.results.find(
  //         (item) => item.problems_identified_id === id
  //       );
  //       if (problem) {
  //         delete problem.label;
  //         delete problem.diagnosis_date;
  //         delete problem.last_addressed_date;
  //         delete problem.icd10_code;
  //         delete problem.hcc_code;

  //         validProblems.push({
  //           ...problem,
  //           abatement: {
  //             ...problem.abatement,
  //             abatementDateTime: dayjs().format("YYYYMMDD"),
  //           },
  //           valid_alert: "Y",
  //         });
  //       }
  //     }
  //   });
  //   [...new Set(invalidIds)].forEach((id) => {
  //     if (
  //       state.problemsIdentified.results &&
  //       state.problemsIdentified.results.length
  //     ) {
  //       const problem = state.problemsIdentified.results.find(
  //         (item) => item.problems_identified_id === id
  //       );
  //       if (problem) {
  //         delete problem.label;
  //         delete problem.diagnosis_date;
  //         delete problem.last_addressed_date;
  //         delete problem.icd10_code;
  //         delete problem.hcc_code;

  //         validProblems.push({
  //           ...problem,
  //           abatement: {
  //             ...problem.abatement,
  //             abatementDateTime: "-",
  //           },
  //           valid_alert: "N",
  //         });
  //       }
  //     }
  //   });

  //   await wcchcServices.validateAlert({
  //     identifier_id: validProblems[0].identifier_id,
  //     validProblems,
  //     invalidProblems,
  //   });

  //   const newData = state.smartAlerts.map((item) => {
  //     if (item.id === alert.id) {
  //       return data;
  //     }
  //     return item;
  //   });

  //   dispatch({ type: "SMART_ALERTS", payload: { smartAlerts: newData } });
  //   setValidIds([]);
  //   setInvalidIds([]);
  //   setSavingChanges(false);
  // };

  // useEffect(() => {
  //   if (alerts.length) {
  //     setAlert(alerts[selectedIndex]);
  //   }

  //   return () => {
  //     setAlert({});
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.parse(JSON.stringify(alerts)), selectedIndex]);

  // useEffect(() => {
  //   setData(JSON.parse(JSON.stringify(alert)));

  //   return () => {
  //     setData([]);
  //   };
  // }, [alert]);

  // useEffect(() => {
  //   setDisabled(JSON.stringify(data) === JSON.stringify(alert));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [JSON.stringify(data), JSON.stringify(alert)]);

  const truncateText = (text, wordLimit = 150) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const highlightTerms = (text, terms) => {
    let highlightedText = text;
    terms.forEach(term => {
      const termRegex = new RegExp(`(${term.termSearched})`, 'gi'); // Regex to match the term case-insensitively
      highlightedText = highlightedText.replace(termRegex, `<span class="${classes.backHighLight}">$1</span>`);
    });
    return highlightedText;
  }

  const highlightedText = highlightTerms(alert?.note_text || '', alert?.searchTerms || []);
  const noteTextForShort = truncateText(alert?.note_text || '');
  const highlightedTextShort = highlightTerms(noteTextForShort, alert?.searchTerms || []);

  const date = alert?.date ? dayjs(alert?.date).format('MM/DD/YYYY') : '';

  return (
    <Box className={classes.alertDetailContainer}>
      
        <CustomToolTip 
            placement="top"      
            title={
                <React.Fragment>
                    <span className={classes.fonthighlight}>{date} </span>
                    <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
                </React.Fragment> 
            }>
            <Typography>
            <Typography>
              <span className={classes.fonthighlight}>{date} </span>
              <span dangerouslySetInnerHTML={{ __html: highlightedTextShort }} />
            </Typography>

            </Typography>
        </CustomToolTip>
      
      <Box className={classes.icdCodes}>
        {alert?.icd && alert?.icd.length
          ? alert.icd.map((icd, index) => (
              <IcdRow
                key={index}
                icd={icd}
                index={index}
                id={alert.id}
                data={data}
                setData={setData}
                validIds={validIds}
                setValidIds={setValidIds}
                invalidIds={invalidIds}
                setInvalidIds={setInvalidIds}
              />
            ))
          : null}
      </Box>
      {/* <Box className={classes.actionDiv}>
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={handleSaveChanges}
          disabled={disabled || savingChanges}
        >
          {savingChanges ? "Saving" : "Save"}
        </Button>
      </Box> */}
    </Box>
  );
};

export default SmartAlertDetails;
