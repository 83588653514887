import React from "react";
import "./ReportTable.scss";
import ReportOuterTableRow from "./ReportOuterTableRow";
import formatHeading from "../../../Utils/formatHeading";
import ReportColumnHeading from "./ReportColumnHeading";


const ReportOuterTable = (props) => {
  const { list, columnList } = props;
  return (
    <div className="ReportOuterTable">
      <section className="section__container">
        <div className="tableWrap">
          {list.length ? (
            <div className="tableScroll">
              <div></div>
              <table className="outer__table">
                <thead>
                  <tr className="tRow">
                    {columnList.map((item, index) =>
                      item.isAvailable ? (
                        <th
                          className={`tableHeading ${
                            item.title === "name" ? "sticky_first" : ""
                          } ${
                            item.title === "payee" ? "last-heading-col" : ""
                          }`}
                          key={index}
                        >
                          <ReportColumnHeading heading={formatHeading(item.title)}></ReportColumnHeading>
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list.map((row, index) => (
                    <ReportOuterTableRow
                      key={index}
                      row={row}
                      columnList={columnList}
                      reportTitle={props?.reportTitle}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="no-result">No Data Available!</div>
          )}
        </div>
      </section>
    </div>
  );
};
export default ReportOuterTable;
