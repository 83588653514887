import React from "react";
import { Tooltip } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    maxWidth: "100% !important",
    width: "fit-content",
    justifyContent: "center",
  },
}));

var options = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const TooltipTable = ({ score, title, type, tableData, service_date_end, iframe }) => {
  const classes = useStyles();
  return (
    <div className="tooltip-table-outer">
      <Tooltip
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        placement={`${iframe ? "auto":"right"}`}
        title={
          <table className="tooltip-table table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>{title}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={
                      item.service_date_end === service_date_end
                        ? { backgroundColor: "#7b7e82" }
                        : null
                    }
                  >
                    <td className="active">
                      {new Date(item.service_date_end).toLocaleDateString(
                        "en",
                        options
                      )}
                    </td>
                    <td className="active">{Number(item[type]).toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
        interactive="true"
        arrow
      >
        <span className="md-txt click-hover" style={{ width: "90px" }}>
          {Number(score).toFixed(2)}
          <HistoryIcon
            style={{ fontSize: "1.15rem", marginLeft: "4px" }}
          ></HistoryIcon>
        </span>
      </Tooltip>
    </div>
  );
};

export default TooltipTable;
