import React, { useEffect, useState } from 'react';
import {Box, Modal, Typography} from '@mui/material';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { ClientDetailsTooltip } from "../../Components/Genesis/Tooltip";
import { generateColor2 } from '../../Utils/generateColorForCohortChart';
import {deepClone} from "../../Utils/filterPatientList";
import { createNumberArray } from '../../Utils/createNumberArray';
import genesisServices from "../../Services/genesisServices";
import Loader from '../../Components/Genesis/Loader';
import ErrorPage from './ErrorPage';

const useStyles = makeStyles((theme) => ({
    modalContainer:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "95vw",
        width: "95vw",
        maxHeight: "95vh",
        backgroundColor: '#fff',
        outline: "none !important",
        // overflow: "scroll"
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        "& > thead > tr > th": {
            textAlign: "center",
            padding: "5px 10px",
            border: "1px solid #acaaa0 !important",
            fontSize: "14px"
        },
        "& > thead > tr > th:nth-child(1)": {
            textAlign: "left !important"
        },
        "& > tbody > tr > td": {
            textAlign: "center",
            padding: "5px 10px",
            border: "1px solid #acaaa0 !important",
            fontSize: "12px",
        },
        "& > tbody > tr > td:nth-child(1)": {
            textAlign: "left !important"
        },
    },
    headerContainer:{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#0080b2",
        alignItems: "center",
        padding: "10px 20px !important"
    },
    headerText:{
       color: "#fff",
       fontSize: "1rem !important"
    },
    headerIcon: {
        fill: "#0080b2",
        cursor: "pointer",
        color: "#fff"
    },
    tableContainer:{
        padding: "20px !important",
        width: "100%",
        height: "calc(100vh - 100px)",
        overflow: "scroll"
    }
}));

const CustomStyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#006DB0",
    },
  });

const ClientDetailsModal = ({open, handleClose, treatmentMonth, location, treatmentType, learnerType}) => {
  console.log("***props", location, treatmentType, learnerType)
  const classes = useStyles();
  const [headerArray, setHeaderArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const tableDataConverter = (data) => {
    let numberArray;
    let finalData = data?.map((rowData) => {
      const { ChildId, Full_Name,cohorts, ...restData } = rowData;
      let length = Object.keys(restData).length / 3;
      numberArray = createNumberArray(length);
      let data2 = numberArray.map((item) => {
        return {
          met: restData[`met_${item}`] ?? "",
          total_objectives: restData[`total_objectives_${item}`] ?? "",
          total_percentage: restData[`total_per_${item}`] ?? "",
          month_number: item,
        };
      });
      return {
        Full_Name,
        data: data2,
      };
    });
    return {
      finalData: [...finalData],
      numberArray: [...numberArray],
    };
  };

  const mergeData = (data) => {
    // Step 1: Merge clientCount and concatenate data arrays
    const merged = data.reduce((acc, curr) => {
        acc.data = acc.data.concat(curr.data);
        return acc;
    }, { data: [] });

    // Step 2: Combine entries with the same month_number
    const aggregatedData = merged.data.reduce((acc, curr) => {
        const found = acc.find(item => item.month_number === curr.month_number);
        
        const met = curr.met === "" ? 0 : curr.met;
        const total_objectives = curr.total_objectives === "" ? 0 : curr.total_objectives;

        if (found) {
            // Check if any of the values are numbers
            const isMetNumber = typeof found.met === 'number' || typeof met === 'number';
            const isTotalObjectivesNumber = typeof found.total_objectives === 'number' || typeof total_objectives === 'number';

            found.met = isMetNumber ? (Number(found.met) + Number(met)) : `${found.met}${met}`;
            found.total_objectives = isTotalObjectivesNumber ? (Number(found.total_objectives) + Number(total_objectives)) : `${found.total_objectives}${total_objectives}`;

            // Calculate total_percentage only if met and total_objectives are numbers
            if (typeof found.met === 'number' && typeof found.total_objectives === 'number') {
                found.total_percentage = Math.trunc(((found.met / found.total_objectives) * 100) || 0);
            }
        } else {
            acc.push({ ...curr, met, total_objectives });
        }
        return acc;
    }, []);

    merged.data = aggregatedData;

    return merged;
  }


  const renderCells = (data, type) => {
    return data?.map(({met, total_objectives, total_percentage}, index) => {

        if (typeof total_percentage === "string") {
              if(type === "data"){
                    return (
                      <td
                        className="empty-data-cell"
                        key={index}
                      ></td>
                    );
              }else{
                    return (
                      <th
                        className="empty-data-cell"
                        key={index}
                      ></th>
                    );
              }
          }

        return(
            <CustomStyledTooltip
                title={<ClientDetailsTooltip data={{met, total_objectives, total_percentage}} />}
                placement="top-start"
                key={index}
            >
              {
                type === "data" ?
                <td 
                    key={index}
                    style={{
                        backgroundColor: generateColor2(total_percentage),
                    }}
                >
                    {`${Math.trunc(total_percentage)}%`}
                </td>
                :
                <th 
                    key={index}
                    style={{
                        backgroundColor: generateColor2(total_percentage),
                    }}
                >
                    {`${Math.trunc(total_percentage)}%`}
                </th>
              }
            </CustomStyledTooltip>
        )
        }

        );
  };

  const renderHeaderCells = () =>{
    return headerArray?.map((headerNumber)=><th>{headerNumber}</th>)
  }

  const getCohortClientDetailsData = async () => {
    try {
      setLoader(true);
      const {data : { data: {client_details} }} = await genesisServices.getCohortClientDetails(treatmentType,location,learnerType);
      let filteredData = client_details?.filter(({cohorts}) => cohorts === treatmentMonth);
      const { finalData, numberArray } = tableDataConverter(deepClone(filteredData));
      let aggrData = mergeData(deepClone(finalData))
      setAggregatedData(aggrData)
      setHeaderArray(numberArray);
      setTableData(finalData);
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage("Something went wrong!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() =>{
    getCohortClientDetailsData()
  },[treatmentMonth, location, treatmentType, learnerType]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
        open={open}
        onClose={() =>handleClose(false, "", 1)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box className={classes.modalContainer}>
        <Box className={classes.headerContainer}>
            <Typography className={classes.headerText}>{treatmentMonth?.replace(/([a-zA-Z])([0-9])/g, '$1 $2')}</Typography>
            <Close
                className={classes.headerIcon}
                onClick={() =>handleClose(false, "", 1)}
            />
        </Box>
        {
          loader ? (
            <Loader />
          ) : error ? (
            <ErrorPage errorMessage={errorMessage} clientDetailModal={true}/>
          ) : (
            <Box className={classes.tableContainer}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th>Full Name</th>
                            {renderHeaderCells()}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData?.map((item, index) =>{
                                return(
                                    <tr key={index}>
                                        <td>{item?.Full_Name}</td>
                                        {renderCells(item?.data, "data")}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <thead>
                        <tr>
                            <th>Total</th>
                            {renderCells(aggregatedData.data, "header")}
                        </tr>
                    </thead>
                </table>
            </Box>
          )
        }
        </Box>
    </Modal>
  )
}

export default ClientDetailsModal;