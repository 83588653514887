import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { DropdownFilterContext } from "../../Contexts/dropdownContext";
import { Backdrop, CircularProgress, FormHelperText, Grid, IconButton, Snackbar } from "@mui/material";
import Close from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import MuiAlert from "@mui/material/Alert";
import services from '../../Services/patientServices';
import formatNumber from "../../Utils/formatNumber";
import { AnalyticsContext } from "../../Contexts/analyticsContext";

const Hr = styled.hr({
  margin: "0.5rem 0rem",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SaveFilter = ({ open, setOpen }) => {
  const { state } = useContext(DropdownFilterContext);
  const [isFetching, setIsFetching] = useState(false);
  const [filterSetName, setFilterSetName] = useState({ value: "", error: "" });
  const [showToast, setShowToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    severity: "",
  });
  const { event } = useContext(AnalyticsContext);
  const patientSetCount = localStorage.getItem("count");
  const currentUser = JSON.parse(localStorage.getItem("user")).user;
  const patientSet = JSON.parse(localStorage.getItem('patient_set'));

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowToast(false);
  };

  const handleClose = () => {
    setFilterSetName({ state: "", error: "" });
    setOpen(false);
  };

  const handleSaveFilter = async () => {
    if (!filterSetName.value) {
      const data = filterSetName;
      data.error = "Filter set name is required";
      setFilterSetName((prev) => ({
        value: prev.value,
        error: "Filter set name is required",
      }));
      return;
    }
    const dropdownFilters = state?.selectedFilters.length;
    const fhs_filters = state?.selectedFiltersCheckbox.length;
    const nlp_filters = Object.keys(state?.nlp_filter).filter((key) => {
      if (state?.nlp_filter[key].value) {
        return 1;
      }
      return 0;
    }).length;
    const inactivePatients = Number(state?.inactivePatients);

    if (!dropdownFilters && !fhs_filters && !nlp_filters && !inactivePatients) {
      setAlertMessage({
        message: "No filter is selected",
        severity: "warning",
      });
      setShowToast(true);
      return;
    }

    const payload = {
      creator_id: currentUser?.user_id,
      cohort_id: patientSet?.patient_set_id,
      name: filterSetName.value,
      filters: JSON.stringify({
        selectedFilters: state?.selectedFilters,
        common_fhs_filters: state?.selectedFiltersCheckbox,
        applied_checkbox: state?.appliedFilterCheckboxes,
        nlp_filter: state?.nlp_filter,
        inactivePatients: state?.inactivePatients,
        selected_date: JSON.parse(localStorage.getItem("patient_set")).selected_date,
      })
    }

    try {
      setIsFetching(true);
      const { data } = await services.saveFilter(payload);
      setAlertMessage({ message: data?.message, severity: 'success' });
      setIsFetching(false);
      setShowToast(true);
      setOpen(false);
      setFilterSetName({ state: "", error: "" });
    } catch (error) {
      setIsFetching(false);
      console.log("Save filter =>", error);
    }

    // GA - Track save filter clicked
    event("patient_list_save_filters", {});
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-save-filter"
        aria-describedby="modal-save-filter"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Typography variant="h6">Save Filters</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Save the filter selection on this cohort as a View. <br/>
                You can filter the cohort with this View at a later or earlier date, <br/>
                and you can compare Views to cohorts and other Views.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Selected Filters:</Typography>
            </Grid>
            <Grid
              container
              item
              xs={10}
              display="flex"
              direction="column"
              className="modifiers-section save-filter-section"
            >
              {state.identifiers_checkbox["options_checkbox"].map(
                (filter, id) => {
                  if (
                    !filter.options.some((opt) =>
                      state.identifiers_checkbox[
                        "selected_checkboxes"
                      ].includes(opt.name)
                    )
                  ) {
                    return null;
                  }

                  return (
                    <div key={id} className="inner-wrapper">
                      <small className="inner-tag">{filter.title}</small>
                      <ul>
                        {filter.options.map((opt, index) => {
                          if (
                            state.identifiers_checkbox[
                              "selected_checkboxes"
                            ].includes(opt.name)
                          ) {
                            return (
                              <li key={index}>
                                {opt.selected === 2 ? (
                                  <small>
                                    <s>{opt.label}</s>
                                  </small>
                                ) : (
                                  <small>{opt.label}</small>
                                )}
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  );
                }
              )}

              {Object.keys(state.nlp_filter).map((key) => {
                if (state.nlp_filter[key]?.value) {
                  return (
                    <div key={key} className="inner-wrapper">
                      <small className="inner-tag">
                        {state.nlp_filter[key]?.label}
                      </small>
                      <ul>
                        <li>
                          <small>
                            {state.nlp_filter[key]?.value === 1
                              ? "Include"
                              : "Not-Include"}
                          </small>
                        </li>
                      </ul>
                    </div>
                  );
                }
                return null;
              })}

              {state?.inactivePatients ? (
                <div className="inner-wrapper">
                  <small className="inner-tag">
                    Inactive patients
                  </small>
                  <ul>
                        <li>
                          <small>
                            {String(state?.inactivePatients)}
                          </small>
                        </li>
                      </ul>
                </div>
              ) : null}

              {state.selectedFilters.map((filter) => {
                return !filter.apply ? null : filter.qf ? (
                  <div key={filter.key} className="inner-wrapper">
                    <small className="inner-tag">Quick-Filter</small>
                    <ul>
                      <li>
                        <small>{filter.title}</small>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div key={filter.key} className="inner-wrapper">
                    <small className="inner-tag">{filter.title}</small>
                    <ul>
                      <li>
                        <small>
                          {filter.key === "cs" ||
                          filter.key === "drc" ||
                          filter.key === "qual" ||
                          filter.key === "imp"
                            ? filter.stratus
                            : filter.to === ""
                            ? `${filter.from}`
                            : filter.from === ""
                            ? `${filter.to}`
                            : filter.key === "dob"
                            ? `${filter.from} to ${filter.to}`
                            : `${filter.from} - ${filter.to}`}
                          {filter.individual === ""
                            ? ""
                            : `${filter.individual}`}
                        </small>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography variant="body1">Number of Patients:</Typography>
              <Typography variant="body1">
                {formatNumber(state.totalCount)}/{patientSetCount}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography variant="body1">Creator Name:</Typography>
              <Typography variant="body1">
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography variant="body1">Filter set name:</Typography>
              <Typography variant="body1">
                <input
                  className="form-control save-filter-input"
                  type="text"
                  value={filterSetName.value}
                  placeholder="Enter filter set name"
                  onChange={(e) =>
                    setFilterSetName({ value: e.target.value, error: "" })
                  }
                />
                {filterSetName?.error && (
                  <FormHelperText error sx={{ marginLeft: "10px" }}>
                    {filterSetName.error}
                  </FormHelperText>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Hr />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="end">
              <button
                className="submit-form-btn"
                onClick={() => handleClose()}
                style={{ width: "150px" }}
              >
                Cancel
              </button>
              <button
                disabled={isFetching}
                className="submit-form-btn"
                onClick={() => handleSaveFilter()}
                style={{ width: "150px" }}
              >
                Save
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="toaster">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleAlertClose}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert
            onClose={handleAlertClose}
            variant="outlined"
            severity={alertMessage?.severity}
          >
            <span>{alertMessage?.message}</span>
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default SaveFilter;
